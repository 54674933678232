import {
  LS
} from "@/const"
// import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import HighlightedBloc from '@/components/highlighted-bloc/highlighted-bloc.vue'
import ActionText from "@/components/action-text/action-text.vue"
import {
  getCard,
} from '@/store/api'
import store from "@/store";

export default {
  name: "Buy",
  components: {
    // GetterWrapper,
    ViewDetails,
    ViewHeader,
    HighlightedBloc,
    ActionText
  },
  props: {
    id: {
      type: Object,
      default: null
    },
  },
  data() {
    return {
      budget: null,
      currentHelp: null,
      store: this.$store.state,
    }
  },
  computed: {
    platform() {
      return this.$store.state.platform
    },
    isFLType() {
      return this.budget.type.startsWith('FL')
    },
    isNaisType() {
      return this.budget.type.startsWith('NAIS')
    },
    isScoType() {
      return this.budget.type.startsWith('SCO')
    },
    isNoelaType() {
      return this.budget.type.startsWith('NOELA')
    },
    isNoeleType() {
      return this.budget.type.startsWith('NOELE')
    },
    isRetrType() {
      return this.budget.type.startsWith('RETR')
    },
    isFetparType() {
      return this.budget.type.startsWith('FETPAR')
    },
    isMarpacType() {
      return this.budget.type.startsWith('MARPAC')
    },
    //card vacances
    isCVType() {
      return this.budget.type.startsWith('CV')
    },
    isCCType() {
      return this.budget.type.startsWith('CC')
    },
    hasXpay() {
      return this.budget.xp
    },
    hasCSE() {
      return this.budget.type === "CE"
    },
    background() {
      let bg = ''
      switch (this.$route.name) {
        case 'card-item-donate':
          bg = 'white'
          break
        case 'card-item-store':
          bg = 'white'
          break
      }
      return bg
    },
    icon() {
      let icon = ''
      switch (this.$route.name) {
        case 'card-item-donate':
          icon = 'sofont-heart'
          break
        case 'card-item-store':
          icon = 'sofont-bag'
          break
      }
      return icon
    },
    text() {
      let text = ''
      switch (this.$route.name) {
        case 'card-item-donate':
          text = this.$t('card.actions.donate')
          break
        case 'card-item-online':
          text = this.$t('card.actions.online')
          break
        case 'card-item-store':
          text = this.$t('card.actions.store')
          break
      }
      return text
    },

  },
  mounted: function () {
    console.log('cards id is ', this.$store.state.cards)
    this.$store.state.cards.forEach(value => {
      const budgetTmp = value.budgets.find(c => c.id === this.id)
      if (budgetTmp != null) this.budget = budgetTmp
    })
  },
  beforeUnmount: function () {
  },
  methods: {
    formatText(textObj) {
      if (textObj.text.includes('{maxTopup}') && this.budget) {
        textObj.text = textObj.text.replace('{maxTopup}', this.$n(this.budget.maxTopup | '0', 'currency'))
      }
      return textObj
    },
    itemCSEvisible(index) {
      if (index === 2 || index === 3) {
        return this.budget?.type === "CE"
      }
      return true
    },
    itemXpayVisible(index) {
      if (index === 1) {
        return true
      }
      return true

    },
    getBlocs() {
      console.log('get blocs', this.$tm('card.store.blocs').filter(bloc => bloc.title !== ''))
      return this.$tm('card.store.blocs').filter(bloc => bloc.title !== '')
    },
    getItemsNotEmpty(items) {
      console.log('get items not empty', this.$tm('card.store.ccType.list'))
      return items.filter(item => item.trim().length > 0)
    },
    hasItems(items){
      console.log('has items', items)
      return items.length > 0
    },
    goToBanners() {
      this.$router.push({name: 'card-item-donate', params: {type: 'shop'}})
    },
    goToExternalUrl() {
      window.open(`${this.budget.externalUrl ? this.budget.externalUrl : "#"}`, '_blank')
    },
    goToBalance() {
      console.log('go to balance', this.platform)
      this.$router.push({name: 'card-item-balance'})
    },
    openHelp(index) {
      console.log('open help', index)
      console.log('open help', this.currentHelp)
      this.currentHelp = (this.currentHelp === index) ? -1 : index
      console.log('currentHelp', this.currentHelp)
    },
    openXpay() {
      this.$router.push({
        name: 'card-item-xpay',
        params: {id: store.state.currentCard.id}
      })
    }
  }
}
