import moment from "moment-timezone"
import ActionText from "@/components/action-text/action-text";

export default {
  name: 'ItemTransaction',
  components: {ActionText},
  props: {
    isOpenned: {
      type: Boolean,
      default: false
    },
    open: {
      type: Function,
      default: () => {
      }
    },
    transaction: {
      type: Object,
      default: () => null,
      required: true
    },
    isRewardPage: {
      type: Boolean,
      defaut: false,
      required: false
    }
  },
  data() {
    return {}
  },
  emits: ['open'],
  mounted() {
    console.log(this.transaction)
  },
  computed: {
    label() {
      console.log('type ', this.transaction.metas.TYPE_M.toLowerCase())
      // console.log('transaction metas ', this.transaction.metas)
      if (this.transaction.metas.TYPE_M.toLowerCase() === "expirationrefund") {
        const variable = this.transaction.metas.labelVariable_0
        return this.$t('entries.expirationRefund') + (variable !== undefined ? variable : '')
      } else if (this.transaction.metas.TYPE_M.toLowerCase() === 'expiration') {
        const variable0 = this.transaction.metas.labelVariable_0
        const variable1 = this.transaction.metas.labelVariable_1
        return this.$t('entries.' + (this.transaction.metas.TYPE_M.toLowerCase()), {label: variable0 !== undefined ? variable0 : ' '}) + (variable1 !== undefined ? variable1 : ' ')
      }
      return this.$t('entries.' + (this.transaction.metas.TYPE_M.toLowerCase()), {value: '' + this.transaction.label + ''})

    }
  },
  methods: {
    formatDate(date) {
      const m = moment(new Date(date))
      return m.format('LLL')
    },
    getError(transaction) {
      if (transaction.metas.ERROR_CODE) return transaction.metas.ERROR_CODE
      return null
    }
  }
}
