<template>
  <!-- Request cards data -->
    <div class="refund-iban row align-center">
      <ViewHeader
        :hasBack="true"
        urlBack="card-item"
        :title="$t('card.actions.refund')"
        icon="sofont-refund"
      />
      <div class="columns small-12 medium-10 large-8">
          <h2 v-html="$t('card.refundIban.title')"></h2>
          <p class="text-left" v-html="$t('card.refundIban.message')"></p>
          <FormWrapper
            :api="addBankInfo.bind(this, this.formRib)"
            btn-text="Valider le virement"
            :validation="v$.formRib"
            @submitted="onSubmitted"
            @error="onError"
          >
            <input type="hidden" name="cardId" :value="$route.params.id" />
            <fieldset>
              <div class="row">
                <InputWrapper isRequired label="Iban" class="columns small-12"   :validation-path="'formRib.iban'">
                  <input
                    id="iban"
                    name="iban"
                    type="text"
                    v-model.trim.lazy="v$.formRib.iban.$model"
                    required
                  />
                </InputWrapper>
              </div>
              <div class="row">
                <InputWrapper isRequired label="BIC" class="columns small-12" :validation-path="'formRib.bic'">
                  <input
                    name="bic"
                    id="bic"
                    type="text"
                    v-model.trim.lazy="v$.formRib.bic.$model"
                    required
                  />
                </InputWrapper>
              </div>
              <div class="row">
                <InputWrapper isRequired label="Adresse" class="columns small-12" :validation-path="'formRib.address'">
                  <input
                    name="adresse"
                    id="address"
                    type="text"
                    v-model.trim.lazy="v$.formRib.address.$model"
                    required
                  />
                </InputWrapper>
              </div>
              <div class="row">
                <div class="columns small-12 medium-4">
                  <InputWrapper isRequired label="Code postal" :validation-path="'formRib.cp'">
                    <input
                      name="cp"
                      id="cp"
                      type="text"
                      maxlength="5"
                      v-model.trim.lazy="v$.formRib.cp.$model"
                      required
                    />
                  </InputWrapper>
                </div>
                <div class="columns small-12 medium-8">
                  <InputWrapper isRequired label="Ville" :validation-path="'formRib.city'">
                    <input
                      name="city"
                      id="city"
                      type="text"
                      v-model.trim.lazy="v$.formRib.city.$model"
                      required
                    />
                  </InputWrapper>
                </div>
              </div>
              <div class="row text-left padding-left-1">
                <div class="padding-right-0">
                  <input
                    id="honorCert"
                    name="honorCert"
                    type="checkbox"
                    style="margin-top: 3px;"
                    required
                    v-model="v$.formRib.honorCert.$model"
                  />
                </div>
                <div class="column small-11 padding-left-1">
                  <label for="honorCert">
                    Je certifie sur l'honneur que je suis le titulaire du compte bancaire correspondant au RIB renseigné ci-dessus.
                  </label>
                </div>
              </div>
            </fieldset>
          </FormWrapper>

        <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
          <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
          <p class="popup-valid-text text-center" v-html="$t('card.refundIban.popup.sendOk')"></p>
          <p v-html="$t('card.refundIban.popup.sendDetails')"></p>
        </Popup>
      </div>
    </div>
</template>

<script src="./refund-iban.js"></script>
