<template>
  <div class="account row align-center">
    <ViewHeader :title="$t('account.title')"/>
    <div class="columns small-12 medium-10 large-8">
      <!-- Account actions -->
      <div class="row small-up-1 medium-up-2 large-up-2">
        <div class="column" id="btn-informations">
          <BtnUrlAction
            :infos="{
                text: $t('account.actions.informations'),
                background: 'none',
                icon: 'sofont-account active',
                route: {
                  name: 'account-informations'
                }
              }"
          />
        </div>
        <div class="column" id="btn-security">
          <BtnUrlAction
            :infos="{
                text: $t('account.actions.security'),
                background: 'none',
                icon: 'sofont-lock',
                route: {
                  name: 'account-security'
                }
              }"
          />
        </div>
        <div class="column" id="btn-preferences">
          <BtnUrlAction
            :infos="{
                text: $t('account.actions.preferences'),
                background: 'none',
                icon: 'sofont-options',
                route: {
                  name: 'account-preferences'
                }
              }"
          />
        </div>
        <div class="column" id="btn-cgu">
          <BtnUrlAction
            :infos="{
                text: $t('account.actions.cgu'),
                icon: 'sofont-documents',
                route: {
                  name: 'cgu'
                }
              }"
          />
        </div>
        <div class="column" id="btn-politics">
          <BtnUrlAction
            :infos="{
                text: $t('account.actions.politics'),
                icon: 'sofont-confidential',
                route: {
                  name: 'politics'
                }
              }"
          />
        </div>
        <div class="column" id="btn-disconnection">
          <BtnUrlAction
            :infos="{
                text: $t('account.actions.disconnection'),
                icon: 'sofont-disconnection',
                route: {
                  name: 'logout'
                }
              }"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./account.js"></script>
