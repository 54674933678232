<template>
  <div class="item-reward row">
    <div id="left" class="column small-3 large-3 left">
      <span v-if="dateDistribution()">{{ $d(dateDistribution(), 'short') }}</span>
      <span v-if="dateDistribution()">{{ $d(dateDistribution(), 'time') }}</span>
    </div>
    <div id="center" class="column small-6 large-6 center">
      <span class="label">{{ label }}</span>
      <span class="label">{{ $t('expiration.of',{value:$n(expiration.amount, 'currency') })}}</span>
      <div v-for="(budget, index) in expiration.budgets" :key="'kit-ui-bloc-' + index">
        <i :class="`sofont sofont-budget-${budget.toLowerCase()}`" style="font-size: 16px"/>
      </div>

    </div>
    <div id="right" class="column small-3 large-3 right">
        <span v-if="amountConsumption()===expiration.amount" :class="`amount`">
          {{ $t('expiration.consumed', {value: $n(amountConsumption(), 'currency')}) }}
        </span>
      <span v-else :class="`amount`">
          {{ $t('expiration.not-consumed',{value:$n(expiration.amount-amountConsumption(), 'currency') }) }}
        </span>
      <span v-if="isExpired()" class="microcopies">
        {{ $t('expiration.expired', {value: $d(expiration.metas.DATE_EXPIR, 'date')+' '+$d(expiration.metas.DATE_EXPIR, 'time')}) }}
      </span>
      <span v-else :class="`microcopies ${isUrgent()?'expire-at':''}`">
        {{ $t('expiration.expire-at', {value: $d(expiration.metas.DATE_EXPIR, 'date')+' '+$d(expiration.metas.DATE_EXPIR, 'time')}) }}
      </span>

    </div>
    <div v-if="isExpired()" class="column small-1 large-1 picto-expire">
      <i :class="`sofont sofont-expired`" style="font-size: 16px;color: var(--color-dark-blue)"/>
    </div>
    <div v-else class="column small-1 large-1 picto-expire">
      <i :class="`sofont sofont-to-expire`" style="font-size: 16px; color: var(--color-dark-blue)"/>
    </div>
  </div>
</template>

<script src="./item-reward.js"></script>
<style scoped lang="scss">
@import '~@/styles/theme';

.item-reward {
  display: flex;
  border-bottom: 1px solid var(--Medium-Grey, #D7E0EE);
  padding: 12px 0px;

  .left {
    display: flex;
    width: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 4px;
    color: $dark-blue;
    font-family: $primary-font-family;
    font-size: $font-size-xs;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .center {
    display: flex;
    flex-direction: column;
    //justify-content: center;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    align-self: stretch;
    overflow: hidden;
    white-space: nowrap;

    .label {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      color: $dark-blue;
      font-family: $primary-font-family;
      font-size: $font-size-s;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .sofont {
      color: $dark-blue;
    }
  }

  .right {
    display: flex;
    width: 93px;
    flex-direction: column;
    align-items: flex-end;

    font-family: $primary-font-family;
    font-size: $font-size-p;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    align-self: center;
    padding: 0;
    margin: 0;
  }

  .amount {
    color: $dark-blue;
    font-family: $primary-font-family;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 19.8px */

    display: flex;
    padding: 0px 9px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: $light-gray;
  }

  .picto-expire {
    align-items: center;
    justify-self: center;
    font-size: 16px;
    color: $dark-blue;
    align-self: center;
  }

  .expire-at {
    color: $danger;
  }

  &:last-child {
    margin-bottom: $space-l;
  }

  .transaction-addons {
    margin-top: 0.5rem;
  }

  .expiration-text {
    color: $red;
  }
}
</style>
