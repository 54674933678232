<template>
  <div class="card-item-wrapper" ref="cardItemWrapper">
    <div :class="`card-item card-item-wallester ${active? 'active' : 'not-active'}` ">
      <Spinner v-if="loading"/>
      <div v-if="flipEnabled" class="scene">
        <div class="card" :class="[flipped ? 'is-flipped' : '']">
          <div class="card__face card__face--front">
            <CardContentWallester
              :card="card"
              :type="'front'"
              :displayedCard="displayedCard"
            />
          </div>
          <div class="card__face card__face--back">
            <CardContentWallester
              :card="card"
              :type="'back'"
              :displayedCard="displayedCard"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <div class="card">
          <div class="card__face card__face--front">
            <CardContentWallester
              :card="card"
              :type="'front'"
              :action="action"
              :displayedCard="displayedCard"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="active && card.statusAcceptationCard !== 'WAITING'" class="card-item__footer">
      <CardItemActions :card="card"/>
    </div>
  </div>
</template>

<script src="./card-item-wallester.js"></script>
<style scoped lang="scss" src="./card-item-wallester.scss"></style>
