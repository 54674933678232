<template>
  <!-- Request cards data -->
  <GetterWrapper id="rewards" :api-load="apiLoad" @data="onData">
    <div class="rewards row align-center">
      <ViewHeader
        :hasBack="true"
        urlBack="home"
        :title="$t('card.actions.rewards')"
      />
      <div class="columns small-12 medium-8 large-8">
        <div class="row">
          <div class="columns text-center info">{{ $t("card.rewards.info") }}</div>
        </div>
        <div
          v-if="(!expirations || expirations.length === 0)"
          class="row"
        >
          <div class="columns text-center">{{ $t("card.rewards.empty") }}</div>
        </div>

        <!-- Current expirations -->
        <div v-if="expirations && expirations.length > 0" class="row">
          <div class="columns">
            <ItemReward
              v-for="(expiration, index) in expirations"
              :expiration="expiration"
              :key="index"
            />
          </div>
        </div>
        <!-- Actions texts -->
        <div
          v-if="(expirations.length>0)"
          class="row small-up-2 medium-up-2 large-up-2 align-center"
        >
          <button
            v-if="hasMoreExpirations"
            ref="addToHome"
            id="add-to-home"
            class="button mx-auto"
            v-html="$t('card.rewards.more')"
            @click="addExpirations"
          >
          </button>

        </div>
      </div>
    </div>
  </GetterWrapper>
</template>

<script src="./rewards.js"></script>
<style scoped lang="scss">
.rewards {
  .view-header {
    margin-top: 40px;
    margin-bottom: 46px;
  }
  .info{
    padding-bottom: 16px;
  }
}
</style>
