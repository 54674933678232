import {LS} from "@/const"
import BtnUrlAction from "@/components/btn-url-action/btn-url-action.vue"
import CardItem from "@/components/card-item/card-item.vue"
import CardItemWallester from "@/components/card-item-wallester/card-item-wallester.vue"
import CardRapydShow from "@/components/card-rapyd-show/card-rapyd-show.vue"
import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import SimulatePop from '@/components/simulate-pay-popup/simulate-pay-popup.vue';
import RoundIcon from "@/components/round-icon/round-icon.vue"
import Popup from '@/components/popup/popup.vue'
import {formatSeconds} from "@/helpers/date.js"
import {changeNumberFormats} from "@/helpers/i18n"
import {putBlockCard, putUnblockCard} from "@/store/api"
import store from "@/store"
import {EventBus} from '@/event-bus.js'
import router from "@/router";
import BtnCardAction from "@/components/btn-card-action/btn-card-action.vue";
import moment from "moment-timezone";

export default {
  name: "CardItemActions",
  components: {
    BtnCardAction,
    BtnUrlAction,
    CardItem,
    ViewHeader,
    ViewDetails,
    CardRapydShow,
    Popup,
    SimulatePop,
    RoundIcon
  },
  props: {
    card: {
      type: Object, defaut: () => {
      }
    },
  },
  data() {
    return {
      cardShown: false,
      cardShown3ds: false,
      cardShownPin: false,
      cardBlocked: false,
      blockQuestion: false,
      actionOnCard: null,
      countDown: LS.CARD_SHOW_TIMOUT_SECS,
      store: this.$store.state,
      activateStatus: this.$route.params.status,
      timer: "",
      loading: false,
      showMaintenancePop: false,
      showSimulatePop: false,
      plasticCard: false,
      platform: {}
    }
  },
  computed: {
    formatedCountdown() {
      return formatSeconds(this.countDown)
    },
    isMaintenanceMode() {
      console.log('card is maintenance mode ? ', this.card.program.underMaintenance)
      return this.card.program.underMaintenance
    },
  },
  methods: {
    closeSucessActivatedMessage() {
      this.card.statusAcceptationCard = 'ACCEPTED'
      this.activateStatus = null
    },
    countDownTimer() {
      this.timer = setTimeout(() => {
        this.countDown -= 1
        if (this.countDown > 0) {
          this.countDownTimer()
        } else if (this.countDown <= 0) {
          this.hideCard()
        }
      }, 1000)
    },
    hideCard() {
      EventBus.emit('card-hide', this.card.id)
      clearTimeout(this.timer)
      this.cardShown = false
      this.countDown = LS.CARD_SHOW_TIMOUT_SECS
    },
    async showCardDetails() {
      console.log(this.card.program)
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.cardShown = true
      EventBus.emit('card-shown', this.card.id)
      // Désactivé temporairement
      if (this.card.program.monetician !== "IBAN") {
        this.countDownTimer()
      }
    },

    async closeCardDetails() {
      console.log(this.card.program)
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      EventBus.emit('card-hide', this.card.id)
      this.cardShown = false
    },
    async show3ds() {
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.actionOnCard = '3ds'
      this.cardShown3ds = true
      EventBus.emit('card-3ds-shown', this.card.id)
    },
    async showPin() {
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.actionOnCard = 'pin'
      this.cardShownPin = true
      EventBus.emit('code-pin-shown', this.card.id)
    },
    async blockAction() {
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.blockQuestion = true
      this.cardShown = false
    },
    async cancelBlockAction() {
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.blockQuestion = false
    },
    async block() {
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.loading = true
      try {
        await putBlockCard(this.card.id)
        this.card.blocked = true
        this.card.dateBlocked = moment().toISOString()
        this.cardBlocked = true
        this.actionOnCard = 'blocked'
        this.blockQuestion = false
        EventBus.emit("card-blocked", this.card.id)
      } catch (error) {
        console.error(error)
        this.$emit('error')
      } finally {
        this.loading = false
      }
    },

    async unblock() {
      if (this.isMaintenanceMode) {
        this.showMaintenancePop = true
        return;
      }
      this.loading = true
      try {
        await putUnblockCard(this.card.id)
        this.card.blocked = false
        this.card.dateBlocked = null
        this.cardBlocked = false
        this.actionOnCard = null
        EventBus.emit("card-unblocked", this.card.id)
      } catch (error) {
        console.error(error)
        this.$emit('error')
      } finally {
        this.loading = false
      }
    },
    close3dsdMessage() {
      this.actionOnCard = null
      this.cardShown3ds = false
      EventBus.emit('card-3ds-hide', this.card.id)
    },

    closePindMessage() {
      this.actionOnCard = null
      this.cardShownPin = false
      EventBus.emit('code-pin-hide', this.card.id)
    },

    async goToActivateCard() {
      router.push({
        name: 'card-item-activate',
        params: {id: this.card.id}
      })
    }
  },
  mounted() {
    this.plasticCard = this.card.plastic
    this.cardBlocked = this.card.blocked
    this.platform = this.$store.state.platform
  }
}
