<template>
  <!-- Request cards data -->
  <!--  <GetterWrapper id="store" :api-load="apiLoad" @data="onData">-->
  <div class="store row align-center">
    <ViewHeader :hasBack="true" urlBack="home" :title="text" :icon="icon" :backgroundIcon="background"/>
    <div class="columns small-12 medium-10 large-8" v-if="budget">
      <ViewDetails v-if="isFLType" :content="$t('card.store.flType.intro')"/>
      <ViewDetails v-else-if="isNaisType" :content="$t('card.store.naisType.intro')"/>
      <ViewDetails v-else-if="isScoType" :content="$t('card.store.scoType.intro')"/>
      <ViewDetails v-else-if="isCVType" :content="$t('card.store.cvType.intro')"/>
      <ViewDetails v-else-if="isCCType" :content="$t('card.store.ccType.intro')"/>
      <ViewDetails v-else :content="$t('card.store.intro', {value : hasXpay  ? $t('card.store.introXpay') : ''})"/>

      <div v-if="isFLType" class="fl-type">
        <p> {{ $t('card.store.flType.subintro') }}</p>
        <div class="text-center">
          <button id="filter-loop-url" class="button" @click="goToExternalUrl">{{
              $t('card.store.flType.button')
            }}
          </button>
        </div>
      </div>

      <div v-else>

        <p v-if="isCVType"> {{ $t('card.store.cvType.subintro') }}</p>
        <p v-else-if="isCCType">{{ $t('card.store.ccType.subintro') }}</p>
        <p v-else-if="isNaisType">{{ $t('card.store.naisType.subintro') }}</p>
        <p v-else-if="isScoType">{{ $t('card.store.scoType.subintro') }}</p>
        <p v-else-if="isNoelaType">{{ $t('card.store.noelaType.subintro') }}</p>
        <p v-else-if="isNoeleType">{{ $t('card.store.noeleType.subintro') }}</p>
        <p v-else-if="isRetrType">{{ $t('card.store.retrType.subintro') }}</p>
        <p v-else-if="isFetparType">{{ $t('card.store.fetparType.subintro') }}</p>
        <p v-else-if="isMarpacType">{{ $t('card.store.marpacType.subintro') }}</p>
        <p v-else>{{ $t('card.store.subintro') }}</p>

        <ul v-if="isCVType  && hasItems($tm('card.store.cvType.list')) ">
          <li v-for="(item, index) in  getItemsNotEmpty($tm('card.store.cvType.list'))" :class="item.length"
              :key="'list-'+index">
            {{ item }}
          </li>
        </ul>
        <ul v-else-if="isCCType && hasItems($tm('card.store.ccType.list')) ">
          <li v-for="(item, index) in  getItemsNotEmpty($tm('card.store.ccType.list'))" :class="item.length"
              :key="'list-'+index">
            {{ item }}
          </li>
        </ul>
        <ul v-else-if="isNaisType && hasItems( $tm('card.store.naisType.list')) ">
          <li v-for="(item, index) in  getItemsNotEmpty($tm('card.store.naisType.list'))" :class="item.length"
              :key="'list-'+index">
            {{ item }}
          </li>
        </ul>
        <ul v-else-if="isScoType && hasItems($tm('card.store.scoType.list')) ">
          <li v-for="(item, index) in  getItemsNotEmpty($tm('card.store.scoType.list'))" :class="item.length"
              :key="'list-'+index">
            {{ item }}
          </li>
        </ul>
        <ul v-else-if="isNoelaType && hasItems($tm('card.store.noelaType.list')) ">
          <li v-for="(item, index) in  getItemsNotEmpty($tm('card.store.noelaType.list'))" :class="item.length"
              :key="'list-'+index">
            {{ item }}
          </li>
        </ul>
        <ul v-else-if="isNoeleType && hasItems($tm('card.store.noeleType.list')) ">
          <li v-for="(item, index) in  getItemsNotEmpty($tm('card.store.noeleType.list'))" :class="item.length"
              :key="'list-'+index">
            {{ item }}
          </li>
        </ul>
        <ul v-else-if="isRetrType && hasItems($tm('card.store.retrType.list')) ">
          <li v-for="(item, index) in  getItemsNotEmpty($tm('card.store.retrType.list'))" :class="item.length"
              :key="'list-'+index">
            {{ item }}
          </li>
        </ul>
        <ul v-else-if="isFetparType  && hasItems($tm('card.store.fetparType.list')) ">
          <li v-for="(item, index) in  getItemsNotEmpty($tm('card.store.fetparType.list'))" :class="item.length"
              :key="'list-'+index">
            {{ item }}
          </li>
        </ul>
        <ul v-else-if="isMarpacType && hasItems($tm('card.store.marpacType.list')) ">
          <li v-for="(item, index) in  getItemsNotEmpty($tm('card.store.marpacType.list'))" :class="item.length"
              :key="'list-'+index">
            {{ item }}
          </li>
        </ul>
        <ul v-else-if="!isCVType && !isCCType && hasItems($tm('card.store.sublist'))">
          <li v-for="(item, index) in  getItemsNotEmpty($tm('card.store.sublist'))"
              :class="item.length &&  itemCSEvisible(index) ? '' : 'is-hidden'" :key="'list-'+index">{{ item }}
          </li>
        </ul>
        <!--          <HighlightedBloc v-if="!isCVType && !isCCType" :texts="$tm('card.store.highlight')" :onClick="goToBanners"/>-->
      </div>
      <div v-if="getBlocs().length>1" class="margin-top-3">
        <div class="store-help-bloc" :class="itemXpayVisible(index) ? '' : 'is-hidden'"
             v-for="(bloc, index) in getBlocs()" :key="'store-bloc-' + index">

          <ActionText class="help-subject" :icon="'sofont-arrow-down' + (currentHelp === index ? ' is-up' : '')"
                      :isTitle="true" :text="bloc.title" v-on:action="openHelp(index)"/>
          <p v-if="currentHelp === index && bloc.text" v-html="bloc.text" class="column"></p>
          <ul class="step-list column" v-if="currentHelp === index && bloc.list">
            <li class="step-item" v-for="(item, index) in bloc.list" :key="'list-'+index">
              <div class="step-icon">
                <span>{{ index + 1 }}</span>
              </div>
              <span>{{ item }}</span>
            </li>
          </ul>
          <div v-if="currentHelp === index && bloc.listend" class="column text-center">
            <div class="step-item step-center last-step">
              <div class="step-icon"><i class="sofont sofont-check"></i></div>
              <p><strong v-html="bloc.listend"></strong></p>
            </div>
          </div>

          <HighlightedBloc v-if="currentHelp === index && bloc.highlight" :texts="formatText(bloc.highlight)"
                           :onClick="goToBalance"/>
          <div v-if=" currentHelp === 1 && index === 1" class="xpay-image"></div>
          <div class="text-center">
            <button id="add-card" v-if="currentHelp === 1 && index === 1" class="button" @click="openXpay">
              {{ bloc.button }}
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="store-help-bloc" :class="itemXpayVisible(index) ? '' : 'is-hidden'"
             v-for="(bloc, index) in getBlocs()" :key="'store-bloc-' + index">
          <ActionText style="cursor: auto;" class="help-subject"
                      :isTitle="true" :text="bloc.title"/>
          <p v-html="bloc.text" class="column"></p>
          <ul class="step-list column">
            <li class="step-item" v-for="(item, index) in bloc.list" :key="'list-'+index">
              <div class="step-icon">
                <span>{{ index + 1 }}</span>
              </div>
              <span>{{ item }}</span>
            </li>
          </ul>
          <div class="column text-center">
            <div v-if="bloc.listend && bloc.listend.length>0" class="step-item step-center last-step">
              <div class="step-icon"><i class="sofont sofont-check"></i></div>
              <p><strong v-html="bloc.listend"></strong></p>
            </div>
          </div>

          <HighlightedBloc v-if="bloc.highlight && bloc.highlight.title.length>1" :texts="formatText(bloc.highlight)"
                           :onClick="goToBalance"/>
          <div class="xpay-image"></div>
          <div class="text-center">
            <button id="add-card" class="button" @click="openXpay">
              {{ bloc.button }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--  </GetterWrapper>-->
</template>

<script src="./store.js"></script>

<style lang="scss" src="./store.scss">
</style>
