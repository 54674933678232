<template>
  <div class="politics row align-center">
    <ViewHeader :hasBack="true" :title="$t('politics.title')"/>
    <div class="columns small-12 medium-10 large-8">
      <ViewBlocs :blocs="$tm('politics.blocs')" section="politics" />
    </div>
  </div>
</template>

<script src="./politics.js"></script>
