import {
  LS
} from "@/const"
import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import store from "@/store"
import {createWidget} from '@charitips/embed';

import {
  formatSeconds
} from "@/helpers/date.js"

import {
  getCard,
  getCardUnmasked,
  getCardBanners, getStoreToken, getStoreUser
} from '@/store/api'
import ViewBlocs from "@/components/view-blocs/view-blocs";

export default {
  name: "Donate",
  components: {
    ViewBlocs,
    GetterWrapper,
    ViewDetails,
    ViewHeader,
  },
  props: {},
  computed: {
    background() {
      let bg = ''
      switch (this.$route.name) {
      case 'card-item-donate':
        bg = 'white'
        break
      }
      return bg
    },
    text() {
      let text = ''
      switch (this.$route.name) {
      case 'card-item-donate':
        text = this.$t('card.actions.donate')
        break
      }
      return text
    },
    items() {
      let items = {}
      switch (this.$route.name) {
      case 'card-item-donate':
        items = {
          0: 'card.buy.donate.shopping',
          1: 'card.buy.donate.help'
        }
        break
      }
      return items
    },
    // Type for the API
    type() {
      let type = null
      switch (this.$route.name) {
      case 'card-item-donate':
        type = "charity"
        break
      }
      return type
    },
    helps() {
      let helps = []
      switch (this.$route.name) {
      case 'card-item-donate':
        helps = Array.isArray(this.$tm('card.buy.donate.blocs')) ? this.$tm('card.buy.donate.blocs') : []
        break
      }
      console.log('helps are ', helps)
      return helps
    },
    helpsFinal() {
      let helpsFinal = ''
      switch (this.$route.name) {
      case 'card-item-donate':
        helpsFinal = this.$tm('card.buy.donate.blocsFinal')
        break
      }
      return helpsFinal
    },
  },
  data() {
    return {
      externalPartnerUrl: '',
      externalKey: '',
      externalBanner: false,
      store: this.$store.state,
      charitipsWidget: null,
      apiLoad: [{
        api: getCard,
        data: {
          id: this.$route.params.id
        }
      },
      {
        api: getCardUnmasked,
        data: {
          id: this.$route.params.id
        }
      },
      ],
      activeItem: '',
      card: {},
      countDown: LS.CARD_SHOW_TIMOUT_SECS,
      timer: "",
      shops: [],
      onlineHelps: Array.isArray(this.$tm('card.buy.online.blocs')) ? this.$tm('card.buy.online.blocs') : [],
      onlineHelpsFinal: this.$tm('card.buy.online.blocsFinal'),
    }
  },
  created: function () {
    this.activeItem = this.items ? '0' : ''
    this.getBanners()
  },
  unmounted() {
    document.querySelectorAll('iframe').forEach(item => {
      item.remove()
    }
    )
    document.querySelectorAll('#charitips_modal_container').forEach(item => {
      item.remove()
    }
    )
    if (this.charitipsWidget) {
      this.charitipsWidget.remove()
      this.charitipsWidget = null
    }
  },
  mounted: function () {
    setTimeout(async () => {
      if (this.$refs.charitips && this.externalBanner) {
        this.charitipsWidget = await this.createWidgetCharitips(this.$refs.charitips)
        if (this.charitipsWidget)
          this.$refs.charitips.appendChild(this.charitipsWidget)
      }
    }, 1000)
  },
  methods: {
    onData(data) {
      this.card = data[0]
      this.card.masked = data[1]
    },
    async getBanners() {
      try {
        const res = await getCardBanners({
          id: this.$route.params.id,
          offset: 0,
          size: 0, // unlimited amount of banners displayed
          type: this.type
        })
        this.shops = res.data
        if(this.shops.length===1){
          if(this.shops[0].type==='external') {
            this.externalPartnerUrl = this.shops[0].bannerUrl;
            this.externalKey = this.shops[0].name;
            this.externalBanner = true;
          }
        }
        console.log('[get banners]', this.shops)
      } catch (error) {
        console.error('[ERROR in get banners]', error)
      }
    },
    setActive(index) {
      this.activeItem = index
      console.log(this.activeItem)
      this.loadCharitips()
    },
    loadCharitips() {
      this.$nextTick(() => {
        if (this.$refs.charitips && this.externalBanner) {
          if (this.charitipsWidget) {
            this.$refs.charitips.appendChild(this.charitipsWidget)
          }
        }
      })
    },

    async createWidgetCharitips(cnt) {
      const user = await getStoreUser()
      let charitipsLocale = user?.locale ?? 'fr-FR'
      if(charitipsLocale === 'fr-FR') charitipsLocale='fr'
      else charitipsLocale='en'

      console.log("local user : ", charitipsLocale)
      console.log("user : ", user)
      return createWidget({
        baseUrl: this.externalPartnerUrl,
        container: cnt,
        publicKey: this.externalKey,
        type: 'donate',
        size: 'fitContent',
        locale: charitipsLocale //'fr' | 'en';
      })
    }
  }
}
