import {LS} from '@/const'
import ActionText from "@/components/action-text/action-text.vue"
import ItemTransaction from "@/components/item-transaction/item-transaction.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import ViewHeader from "@/components/view-header/view-header.vue"

import {getTransactions} from '@/store/api'
import Spinner from "@/components/spinner/spinner.vue";
import {EventBus} from "@/event-bus";
import BudgetItem from "@/components/budget-item/budget-item.vue";

export default {
  name: 'statementBudgets',
  components: {
    BudgetItem,
    Spinner,
    ActionText,
    ItemTransaction,
    ViewDetails,
    ViewHeader
  },
  props: {},
  computed: {},
  data() {
    return {
      card: {},
      currentOffset: 0,
      hasMoreTransactions: true,
      indexCurrent: -1,
      indexPassed: -1,
      transactions: [],
      budgetsAndTransactions: {},
      loading: true,
    }
  },
  created: function () {
  },
  mounted: function () {
    EventBus.on(
      'cardSelected',
      async (cardSelected) => {
        if (cardSelected.id === this.card.id) return
        this.hasMoreTransactions = true
        this.loading = true
        this.card = cardSelected
        let data = await getTransactions({
          id: this.card.id,
          offset: 0,
          size: LS.CARD_TRANSACTION_SIZE,
          order: 'desc'
        });
        this.transactions = []
        this.onData(data.data)
        this.loading = false
      })
  },
  methods: {
    onData(budgetsAndTransactions) {
      this.budgetsAndTransactions = budgetsAndTransactions;
      this.budgets = budgetsAndTransactions.budgets;
      let data = budgetsAndTransactions.transactions;
      if (data.length < LS.CARD_TRANSACTION_SIZE) this.hasMoreTransactions = false
      this.transformTransaction(data)
    },
    async addTransactions() {
      // this.loading = true
      try {
        const res = await getTransactions({
          id: this.card.id,
          offset: this.currentOffset + LS.CARD_TRANSACTION_SIZE,
          size: LS.CARD_TRANSACTION_SIZE,
          order: 'desc'
        })
        if (res.data.transactions.length < LS.CARD_TRANSACTION_SIZE) this.hasMoreTransactions = false
        this.transformTransaction(res.data.transactions)
        this.currentOffset += LS.CARD_TRANSACTION_SIZE
      } catch (error) {
        console.error('[ERROR]', error)
      } finally {
        this.loading = false
      }
    },
    transformTransaction(data) {
      data.forEach(trans => {
        trans.date = new Date(trans.date)
        if (trans.budgets && trans.budgets.filter(f => f === "TOP_UP").length > 0) {
          if (trans.status !== 'PENDING')
            this.transactions.push(trans)
        } else
          this.transactions.push(trans)
      })
    },
    openPassed(index) {
      this.indexPassed = (this.indexPassed === index) ? -1 : index
    }
  }
}
