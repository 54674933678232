<template>
  <div>
    <div class="card__wallester_content ">
      <img v-if="action" :src="bgUrl" class="card__bg darkMode" :alt="`carte ${card.id}`"/>
      <img v-else :src="bgUrl" class="card__bg" :alt="`carte ${card.id}`"/>
      <!--      <CardItemMenu v-if="isCardSelected && type !== 'back'" :card="card"/>-->

      <div :class="`tag card__tag_${type}`">
        <div v-if="card.plastic" class="plastic">
          {{ $t('card.view.plastic') }}
        </div>
        <div v-else class="virtual">
          {{ $t('card.view.virtual') }}
        </div>
        <transition name="fade">
          <CardItemMenu v-if="showMenu" :card="card" :id="`menu-${card.id}`" :key="`menu-${card.id}-${showMenu}`"
                        :class="`card__menu_${type}`"/>
        </transition>
      </div>
      <div class="card__infos">
        <div :class='"card__logo-container" +(action?" darkMode":"")'>
          <img class="logo" :src="programLogoUrl" alt="logo de la carte"/>
        </div>
        <div v-if="type === 'back' && !action" class="card__number card-row">
        <span :id="'number' + index" v-for="(n, index) in cardNumber" :key="'num-' + index">{{
            n
          }}
        </span>
          <button class="copy" @click="copyCardNumber">
            <i class="sofont sofont-copy"></i>
          </button>
        </div>

        <div v-if="type === 'back' && !action" class="card__other card-row">
          <div>
            <span>{{ $t("card_expire") }}</span>
            <span id="card_expire">{{ displayedCard.expiration }}
            <button class="copy" @click="copyCardExpiration">
              <i class="sofont sofont-copy"></i>
            </button>
            </span>
          </div>
          <div>
            <span>{{ $t("card_cvv") }}</span>
            <span id="card_cvv">{{ displayedCard.cvv }}
              <button class="copy" @click="copyCardCvv">
              <i class="sofont sofont-copy"></i>
            </button>
            </span>

          </div>
        </div>
        <div v-if="action === '3ds' && displayedCard.secret" class="card__other card-row">
          <div class="threeDs">
            <h3>{{ $t('card.validationCode') }}</h3>
            <div class="secret">{{ displayedCard.secret }}</div>
            <div class="action">
              <button class="copy" id="card-secret-copy" @click="copyCardExpiration">
                <i class="sofont sofont-copy"></i>
                {{ $t("card.copyCode") }}
              </button>
            </div>
          </div>
        </div>
        <div v-else-if="action === 'pin' && displayedCard.pin" class="card__other card-row">
          <div class="pin">
            <h3>{{ $t('card.pinCode') }}</h3>
            <div class="secret">{{ displayedCard.pin }}</div>
          </div>
        </div>
        <div v-else-if="action === 'blocked'" class="card__other card-row">
          <div class="blocked">
            <div class="card_locked sofont sofont-locked"/>
            <span class="align-center">{{ $t('card.blocked.message', {value: dateBlocked()}) }}</span>
          </div>
        </div>
        <span id="card_name" v-if="!action && cardName" :class="'card__name card__name-' + card.id">{{
            cardName
          }}</span>
        <img v-if="!action && card.graphics" class="card__type" :src="cardTypeLogoUrl"/>
      </div>

    </div>
  </div>
</template>
<script>
import moment from "moment-timezone";
import {EventBus} from "@/event-bus";
import CardItemMenu from "@/components/card-item-menu/card-item-menu.vue";

export default {
  name: "CardContentWallester",
  components: {CardItemMenu},
  props: ["card", "type", "displayedCard"],
  data() {
    return {
      action: null,
      activeCard: false,
      showMenu: true
    }
  },
  mounted() {
    if (this.card?.blocked) {
      this.action = "blocked"
    }

    EventBus.on('cardSelected', (cardSelected) => {
      this.activeCard = this.card.id === cardSelected.id;
      this.showMenu = this.activeCard

      this.$nextTick(() => {
        this.$forceUpdate(); // Forcer le rafraîchissement
      });
    })

    EventBus.on('card-3ds-hide', (cardIdToHide) => { //
      if (cardIdToHide === this.card.id) {
        this.action = null
      }
    })
    EventBus.on('card-3ds-shown', (cardIdToHide) => { //
      if (cardIdToHide === this.card.id) {
        this.action = "3ds"
      }
    })
    EventBus.on('card-3ds-hide', (cardIdToHide) => { //
      if (cardIdToHide === this.card.id) {
        this.action = null
      }
    })
    EventBus.on('code-pin-shown', async (cardIdToHide) => { //
      if (cardIdToHide === this.card.id) {
        this.action = "pin"
      }
    })
    EventBus.on('code-pin-hide', (cardIdToHide) => { //
      if (cardIdToHide === this.card.id) {
        this.action = null
      }
    })
    EventBus.on('card-blocked', (cardIdToHide) => { //
      if (cardIdToHide === this.card.id) {
        this.action = "blocked"
      }
    })
    EventBus.on('card-unblocked', (cardIdToHide) => { //
      if (cardIdToHide === this.card.id) {
        this.action = null
      }
    })
  },
  computed: {
    bgUrl() {
      return this.card
        ? "data:" +
        this.card.graphics.typeMimeBackground +
        ";base64," +
        this.card.graphics.background
        : ""
    },
    programLogoUrl() {
      return (
        "data:" +
        this.card.graphics.typeMimeProgramLogo +
        ";base64," +
        this.card.graphics.programLogo
      )
    },
    cardTypeLogoUrl() {
      return (
        "data:" +
        this.card.graphics.typeMimeCardTypeLogo +
        ";base64," +
        this.card.graphics.cardTypeLogo
      )
    },
    cardName() {
      return (
        this.$store.state.user.firstname + " " + this.$store.state.user.lastname
      )
    },
    cardNumber() {
      const num = this.displayedCard.number
      return [
        num.substring(0, 4),
        num.substring(4, 8),
        num.substring(8, 12),
        num.substring(12, 16)
      ]
    },
    isCardSelected() {
      // return this.card.id === this.$store.state.card.id
      return this.activeCard

    }
  },
  methods: {
    moment,
    dateBlocked() {
      let dateb = Date.parse(this.card.dateBlocked)
      console.log("date:", dateb)
      return moment(dateb).format("DD-MM-YYYY")
    },
    showAlert(text) {
      if (text) {
        this.copyAlert = text
        const to = setTimeout(() => {
          this.copyAlert = false
          clearTimeout(to)
        }, this.copyAlertTimeout)
      } else {
        this.copyAlert = false
      }
    },
    copyCardSecret() {
      navigator.clipboard.writeText(this.displayedCard.secret).then(
        () => {
          console.log("card secret copié ", this.displayedCard.secret)
        },
        () => {
          this.showAlert(false)
        }
      )
    },
    copyCardNumber() {
      navigator.clipboard.writeText(this.displayedCard.number).then(
        () => {
          this.showAlert(this.$t("card.copyAlert"))
        },
        () => {
          this.showAlert(false)
        }
      )
    },
    copyCardCvv() {
      navigator.clipboard.writeText(this.displayedCard.cvv).then(
        () => {
          this.showAlert(this.$t("card.copyAlert"))
        },
        () => {
          this.showAlert(false)
        }
      )
    },
    copyCardExpiration() {
      navigator.clipboard.writeText(this.displayedCard.expiration).then(
        () => {
          this.showAlert(this.$t("card.copyAlert"))
        },
        () => {
          this.showAlert(false)
        }
      )
    }
  }
}
</script>

<style lang="scss">
@import "~@/styles/theme";
@import "~@/styles/mixins";

.slick-slide {
  //height: 600px !important;
  height: auto !important;
  @include small-only {
    width: 260px;
    margin-right: 10px;
    min-width: calc(260px * 7 / 10);
  }
}

.card__wallester_content {
  //margin-right: 10px;
  width: 300px;

  @include small-only {
    width: 260px;
  }

  .copy {
    font-size: 16px;
    cursor: pointer;
  }

  .tag {
    position: absolute;
    right: -10px;
    top: 15.5px;
    display: flex;
    align-items: center;
    z-index: 1000;
    width: 120px;
    height: 32px;
    @include small-only {
      right: -17px;
    }


    .virtual {
      display: flex;
      padding: 3px 6px 4px 6px;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: $white;
      width: 77px;
      color: $dark-blue;
      text-align: center;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px; /* 125% */
      margin-right: 11px;
      height: 20px;
    }

    .plastic {
      display: flex;
      padding: 3px 6px 4px 6px;
      justify-content: center;
      align-items: center;
      width: 77px;
      border-radius: 10px;
      background: $dark-blue;

      color: $white;
      text-align: center;
      font-family: 'Plus Jakarta Sans', sans-serif;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 10px; /* 125% */
      margin-right: 11px;
      height: 20px;
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s; /* Temps de l'animation */
  }

  .fade-enter, .fade-leave-to {
    opacity: 0; /* Invisible lors de l'entrée ou de la sortie */
  }

  .card_locked {
    content: url($card-locked);
  }

  .card__infos {
    justify-content: space-between;
  }

  .darkMode {
    filter: brightness(46%);
  }

  .blocked {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    margin-left: 0 !important;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    .sofont-locked {
      width: 38px;
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(1000%)
    }

    span {
      color: #FFF;
      text-align: center;
      font-family: "Plus Jakarta Sans" !important;
      font-size: 10px !important;
      font-style: normal !important;
      font-weight: 400 !important;
      line-height: normal !important;
    }
  }

  .threeDs {
    position: absolute;
    top: 50px;
    left: 60px;

    h3 {
      color: var(--White, #FFF);
      text-align: center;
      /* H3 */
      font-family: Urbanist;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .secret {
      display: flex;
      padding: 10px;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      border-radius: 8px;
      background: #FFF;

      color: #000;

      text-align: center;
      font-family: "Plus Jakarta Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%; /* 32.4px */
    }

    .action button {
      margin-top: 15px;
      display: flex;
      align-items: flex-start;
      gap: 7px;
      color: var(--White, #FFF);
      text-align: center;
      /* Link */
      font-family: "Plus Jakarta Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }

  .pin {
    position: absolute;
    top: 50px;
    left: 90px;

    h3 {
      color: var(--White, #FFF);
      text-align: center;
      /* H3 */
      font-family: Urbanist;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .secret {
      display: flex;
      padding: 10px;
      align-items: flex-start;
      gap: 10px;
      align-self: stretch;
      border-radius: 8px;
      background: #FFF;

      color: #000;

      text-align: center;
      font-family: "Plus Jakarta Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 180%; /* 32.4px */
    }

    .action button {
      margin-top: 15px;
      display: flex;
      align-items: flex-start;
      gap: 7px;
      color: var(--White, #FFF);
      text-align: center;
      /* Link */
      font-family: "Plus Jakarta Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
</style>
