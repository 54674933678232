
export default {
  name: 'CardIcon',
  components: {},
  props: {
    background: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: "",
      required: true
    },
    fontSize: {
      type: String,
      default: null
    },
    fontColor: {
      type: String,
      default: 'dark-blue'
    }
  },
  data () {
    return {}
  },
  computed: {},
  methods: {
    getStyles() {
      console.log(`fontSize: ${this.fontSize}; color: ${this.fontColor};`)
      return `font-size: ${this.fontSize}; color:var(--color-${this.fontColor});`
    }
  }
}
