<template>
  <!-- Request cards data -->
  <div v-if="card" class="card-item-actions align-center">
    <div v-if="activateStatus==='ACCEPTED'" class="success-activated">
      <h2 class="success">{{ $t('card.activated.success.title') }}</h2>
      <p>{{ $t('card.activated.success.message1') }}</p>
      <p>{{ $t('card.activated.success.message2') }}</p>
      <button class="button" :onclick="closeSucessActivatedMessage">{{
          $t('card.activated.success.button')
        }}
      </button>
    </div>

    <button
      class="button margin-top-1"
      @click="goToActivateCard"
      v-if="!activateStatus && !cardShown && card.statusAcceptationCard === 'WAITING'"
      :disabled="
              !isMaintenanceMode &&
              (card.amount === 0 || card.program.cardEnabled === false)
            "
    >
      {{ $t("card.actions.activate") }}
    </button>
    <div v-if="cardShown3ds" class="info-3ds">
      <p>{{ $t('card.secret.info.3ds') }}</p>
      <button class="button" :onclick="close3dsdMessage">{{
          $t('card.secret.button.close')
        }}
      </button>
    </div>
    <div v-if="cardShownPin" class="info-pin">
      <p>{{ $t('card.secret.info.pin') }}</p>
      <button class="button" :onclick="closePindMessage">{{
          $t('card.secret.button.close')
        }}
      </button>
    </div>
    <div v-if="blockQuestion" class="info-block-card">
      <p>{{ $t('card.block.info') }} <a :href="`mailto:${platform.emailSupport}`">{{ platform.emailSupport }}</a>
      </p>
      <button class="button" :onclick="block">{{
          $t('card.block.button.confirm')
        }}
      </button>
      <br/>
      <button type="button" class="btn btn-cancel" :onclick="cancelBlockAction">{{
          $t('form.cancel')
        }}
      </button>
    </div>
    <div v-if="card.statusAcceptationCard !== 'WAITING'" class="row align-center">
      <btn-card-action
        id="numberCard"
        v-if="!plasticCard && !cardShown && !actionOnCard && !blockQuestion && card.statusAcceptationCard === 'ACCEPTED' && !activateStatus || cardBlocked"
        :infos="{
              background: 'black',
              icon: 'sofont-eye',
              text: $t('card.show')
              }"
        :disabled="cardBlocked"
        :click="showCardDetails"
        class="column"
      />
      <btn-card-action
        id="numberCard"
        v-if="!plasticCard && cardShown && !blockQuestion && card.statusAcceptationCard === 'ACCEPTED' && !activateStatus"
        :infos="{
              background: 'black',
              icon: 'sofont-eye',
              text: formatedCountdown
              }"
        :click='closeCardDetails'
        class="column"
      />
      <btn-card-action
        v-if=" !actionOnCard && card.statusAcceptationCard === 'ACCEPTED' && plasticCard && !blockQuestion && !activateStatus"
        id="pin"
        :infos="{
              background: 'black',
              icon: 'sofont-pin',
              text: $t('card.pin.show')
              }"
        :disabled="cardBlocked"
        :click="showPin"
        class="column"
      />
      <btn-card-action
        v-if=" !actionOnCard && card.statusAcceptationCard === 'ACCEPTED' && !blockQuestion && !activateStatus || cardBlocked"
        id="3ds"
        :infos="{
              background: 'black',
              icon: 'sofont-3ds',
              text: $t('card.3ds.show')
              }"
        :disabled="cardBlocked || cardShown"
        :click="show3ds"
        class="column"
      />
      <btn-card-action
        v-if="!actionOnCard && !cardBlocked && card.statusAcceptationCard === 'ACCEPTED' && !blockQuestion && !activateStatus"
        id="block"
        :disabled="cardShown3ds || cardShown"
        :infos="{
              background: 'black',
              icon: 'sofont-locked',
              text: $t('card.lock')
              }"
        :click="blockAction"
        class="column"
      />
      <btn-card-action
        v-if="cardBlocked && !activateStatus"
        id="unblock"
        :infos="{
              background: 'black',
              icon: 'sofont-unlocked',
              text: $t('card.unlock')
              }"
        :click="unblock"
        class="column"
      />
    </div>
  </div>
</template>

<script src="./card-item-actions.js"></script>

<style lang="scss">
@import "~@/styles/theme";
@import "~@/styles/mixins";

.card-item-actions {
  width: 304px;
  margin: 0;
  padding: 0;

  @include small-only {
    width: 230px;
  }

  .row {
    @include small-only {
      align-items: start;
    }
  }

  .info-3ds, .info-pin, .info-block-card {
    position: relative;
    width: 425px;
    left: -65px;
    @include small-only {
      width: 336px;
      left: -56.6px;    }
  }

  .view-header {
    background: #fff;
    z-index: 2;
  }

  .btn-card-action-text {
    width: 100%;
  }

  .btn-card-action {
    max-width: 100%;
  }

  .btn-card-action-wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .card-item-actions-buttons {
    display: flex;
    align-items: flex-start;
    margin: auto;
  }

  .success-activated {
    h2 {
      color: $primary-color;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 10px;
    }
  }

  .btn-cancel {
    cursor: pointer;
  }

  #btn-donate {
    .round-icon i {
      color: $donate-round-icon-color;
    }
  }

  #btn-simulate {
    button {
      background: $grey; // grey
      color: #fff;
    }

    .round-icon {
      color: #fff;
    }
  }
}
</style>
