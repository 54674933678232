<template>
  <!-- Request cards data -->
  <GetterWrapper id="balance" :api-load="apiLoad" @data="onData">
    <div class="balance row align-center">
      <ViewHeader
        :hasBack="true"
        urlBack="home"
        :title="$t('card.actions.balance')"
      />
      <div class="columns small-12 medium-10 large-8">
        <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
          <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
          <!--          <p class="popup-valid-text text-center" v-html="$t('card.balance.sendOk')"></p>-->
          <!--          <p v-html="$t('card.balance.sendDetails', { value: $n(finalAmount, 'currency') })"></p>-->
          <h3 v-html="$t('card.balance.sendOk')"></h3>
        </Popup>

        <!-- STEPS INDICATOR -->
        <ViewSteps
          :steps="[{ label: $t('card.balance.step.balanceStep1') }, { label: $t('card.balance.step.balanceStep2') }]"
          :activeStep="currentStep"
          @clickstep="onChangeStep"
          :canGoBack="true"
        ></ViewSteps>

        <!-- FIRST STEP -->
        <FormWrapper
          v-if="currentStep === 0"
          :api="null"
          :cancel-disabled="true"
          :btn-text="`${$t('form.validate')}`"
          :btnDisabled="v$.form.$invalid || !canPay"
          :validation="v$.form"
          @submitted="onSubmitted"
          @error="onError"
        >
          <fieldset>
            <div class="row align-center">
              <h2 v-if="isTopupAllowed" class="columns small-12 medium-7 balance-title">{{ $t('card.credit') }} </h2>
              <p v-else class="columns small-12 text-center balance-topup-alert"><strong
                v-html="$t('card.balance.topupError')"></strong></p>
              <InputWrapper class="columns small-12 medium-7 input_wrapper_balance" :validation-path="'form.amount'">
                <div class="balance-currency balance-amount" v-if="getCurrencySymbol() !== '€'">{{
                    getCurrencySymbol()
                  }}
                </div>
                <input
                  :class="`balance-input balance-amount ${getCurrencySymbol() === '€'? 'balance-input-euro':''}`"
                  type="number"
                  id="input_amount"
                  @focusin="onInputFocusin($event)"
                  @focusout="onInputFocusout($event)"
                  step="1"
                  min="0"
                  v-model="v$.form.amount.$model"
                  :disabled="!isTopupAllowed"
                />
                <div class="balance-euros balance-amount" v-if="getCurrencySymbol() === '€'">{{
                    getCurrencySymbol()
                  }}
                </div>
              </InputWrapper>
              <div class="columns small-12 medium-7">
                <p
                  v-if="currentComplement > maxComplement"
                  class="balance-text alert"
                >{{ $t('card.balance.maxComplement',{ value: $n(maxComplement, 'currency') }) }}</p>
                <p class="balance-text">{{ $t('card.balance.indic') }}</p>
              </div>
              <InputWrapper class="columns small-12 medium-7 input-checkbox" :validation-path="'form.terms'">
                <input
                  id="balance-check-input"
                  name="newsletter"
                  type="checkbox"
                  v-model="v$.form.terms.$model"
                  :disabled="!canPay"
                />
                <label
                  for="balance-check-input"
                  class="balance-check"
                  :class="{ 'disabled' : !canPay }"
                >{{ $t('card.balance.check') }}</label>
              </InputWrapper>
            </div>
          </fieldset>
        </FormWrapper>

        <!-- SECOND STEP -->
        <div v-else class="columns">
          <div class="row align-center">
            <div class="columns small-12 medium-7">
              <div class="row flex-dir-row align-middle align-center">
                <LogoVisa class="column small-2"/>
                <LogoMastercard class="column small-2"/>
              </div>
              <h2
                class="balance-amount-title"
              >{{ $t('card.balance.amountTitle') + ' : ' + $n(currentComplement, 'currency') }}</h2>
            </div>
          </div>
          <div class="row align-center">
            <StripeSca
              :amount="currentComplement"
              :cardID="card.id"
              :cardSo="card"
              :secretPaymentIntent="secretStripe"
              :stripeAPIToken="card.program.stripeKey"
              @submitted="onSubmittedStripe"
              @error="onError"
            />
          </div>
        </div>
      </div>
    </div>
  </GetterWrapper>
</template>

<script src="./balance.js"></script>
<style lang="scss">
@import "~@/styles/theme";

.balance {
  .input_wrapper_balance .input-container {
    width: 150px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
  }

  #input_amount {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;

    border-radius: 10px;
    border: 1px solid $medium-gray;
    background: #FFF;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.25);
  }

  #input_amount .balance-input-euro {
    padding: unset;
  }

  .balance-input-euro {
    padding-right: 22px;
    color: $black;
    font-family: $primary-font-family;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%; /* 25.2px */
  }
}
</style>
