import BtnUrlAction from "@/components/btn-url-action/btn-url-action.vue"
import CardItem from "@/components/card-item/card-item.vue"
import CardRapydShow from "@/components/card-rapyd-show/card-rapyd-show.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import SimulatePop from '@/components/simulate-pay-popup/simulate-pay-popup.vue';
import RoundIcon from "@/components/round-icon/round-icon.vue"
import Popup from '@/components/popup/popup.vue'
import BtnCardAction from "@/components/btn-card-action/btn-card-action.vue";
import ActionText from "@/components/action-text/action-text";
import {EventBus} from "@/event-bus";

export default {
  name: "CardItemMenu",
  components: {
    ActionText,
    BtnCardAction,
    BtnUrlAction,
    CardItem,
    ViewHeader,
    ViewDetails,
    CardRapydShow,
    Popup,
    SimulatePop,
    RoundIcon
  },
  props: {
    card: {
      type: Object, default: () => {
      }
    },
  },
  data() {
    return {
      menuOpened: false,
    }
  },
  computed: {
    isMaintenanceMode() {
      console.log('card is maintenance mode ? ', this.card.program.underMaintenance)
      return this.card.program.underMaintenance
    },
    donateEnable() {
      console.log('card is donate enable ? ', this.card)
      return (this.card.budgets.length > 0 && this.card.budgets.filter(b => !b.type.startsWith('FL') && !b.type.startsWith('CC') && !b.type.startsWith('CV') && !b.type.startsWith('NAIS') && !b.type.startsWith('SCO')).length > 0);
    },
    topupEnable() {
      return (this.card.budgets.length > 0 && this.card.budgets.filter(b => !b.type.startsWith('FL')).length > 0);
    },
  },
  methods: {
    closeOrOpen() {
      this.menuOpened = !this.menuOpened;
      if (this.menuOpened) {
        EventBus.emit('showCardMenu', this.card)
      } else {
        EventBus.emit('closeCardMenu', this.card)
      }
    },
    handleClickOutside(event) {
      console.log('click outside refs ', this.$refs.menuWrapper, ' event ', event.target, '')
      if (!this.$refs.menuWrapper && !event.target) return;
      // Vérifie si le clic est en dehors du menu
      if (!this.$refs.menuWrapper?.contains(event.target) && this.menuOpened) {
        this.menuOpened = false;
        EventBus.emit("closeCardMenu", this.card);
      }
    },
    handleEscapeKey(event) {
      // Vérifie si la touche Escape a été pressée
      if (this.menuOpened && (event.key === "Escape" || event.keyCode === 27)) {
        this.menuOpened = false;
        EventBus.emit("closeCardMenu", this.card);
      }
    },


  },
  mounted() {
    // Ajoute un écouteur d'événement global pour le clic
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("keydown", this.handleEscapeKey);

  },
  beforeDestroy() {
    // Nettoie l'écouteur d'événement au démontage
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("keydown", this.handleEscapeKey);

  },

}
