import qs from "qs"
import http from "@/http"
import store from "@/store"
import {LS} from "@/const"
import {loadLanguageAsync} from "@/helpers/i18n"
import {injectCSS, parseQuery, loadTheme} from "@/helpers/theme"
import axios from 'axios'
//console.log('http, ', http)
const Command = async (_method, _url, _data, _options = {mock: false}) => {
  console.log('api cmd ', _options)
  if (_options.mock) {
    return await MockCommand(_method, _url, _data)
  }

  // Buid API endpoint
  let url = `${process.env.VUE_APP_API_URL}${_url}`

  // Can call another url id url starts with 'http'
  if (_url.substr(0, 4) === "http") {
    url = _url
  }

  // Build http request
  const _params = {
    headers: _options.headers,
    params: _options.params,
    responseType: _options.responseType
  }
  const _promise = http[_method](url, _data, _params)

  // Returns promise
  return _promise
}

const ApiCommand = async (_method, _url, _data, _options = {}) => {
  console.debug("new Apicmd :", _url, _options, _method)
  // Send the http API command
  return await Command(_method, _url, _data, _options)
}

//Mockoon command
const MockCommand = async (_method, _url, _data, _options = {}) => {
  console.log('mock cmd')
  const url = `http://localhost:3001/sorewards${_url}`
  return axios[_method](url, _data)
}

// Production or development env ?
export function isProduction() {
  return process.env.NODE_ENV === "production"
  // return true
}

/**
 * SESSION MANAGEMENT
 */
export async function getUserInfos() {
  const res = await getUserApi()
  await setStoreUser(res.data)
  return await getStoreUser()
}

export async function initPlatform() {
  let platform
  await clearStorePlatform()

  // Get the platform
  platform = await getStorePlatform()

  // Complete platform
  if (!platform) {
    let res = null
    if (
      window.location.hostname === "127.0.0.1" ||
      window.location.hostname === "qa.int.sorewards.com" ||
      window.location.hostname === "localhost" ||
      window.location.hostname === "192.168.1.120" ||
      window.location.hostname === "192.168.0.19"
    ) {
      res = await getPlatform({url: process.env.VUE_APP_BRAND})
    } else {
      res = await getPlatform({url: window.location.hostname})
    }
    if (res && res.data) {
      platform = await setStorePlatform(res.data)
    }
  } else {
    await setStorePlatform(platform)
  }

  // Inject CSS if production
  if (platform.theme && platform.theme.cssUrl) {
    await injectCSS(platform.theme.cssUrl)
  }

  // Load theme ?
  const query = parseQuery(document.location.search)
  if (query && query.theme) {
    await loadTheme(platform, query.theme)
  }
}

// Init the session
export async function checkUserSession() {
  console.log('check user session')
  // Init vars
  let user = null
  let token = null
  let refreshToken = null
  let platform = null

  // Show offline mode if an error occured (ie: backend is offline)
  try {
    // Get knowns tokens
    const tokens = await getStoreToken()
    token = tokens.token
    refreshToken = tokens.refreshToken
    // console.log('API check user session  : tokens ', tokens)
    // Get the platform
    platform = await getStorePlatform()
    // Set the language for the user (or take browser default language if not logged)
    await loadLanguageAsync(navigator.language)

    // Check that token is correct
    if (token && token.length) {
      // console.log('API check user session , token exists so set Store token')
      // Inject token and refreshToken to store
      await setStoreToken(token, refreshToken)

      // Fetch user infos for store
      user = await getUserInfos()
      // console.log('API check user session user is  ', user, 'so set store user')
      await setStoreUser(user)
    } else {
      return Promise.reject(new Error(`No token found`))
    }
  } catch (err) {
    // console.log('error', err)
    await clearSession()
    throw err
  }

  // Returns found datas
  return {
    user,
    token,
    platform
  }
}

// STORE TOKEN MANAGEMENT
export async function getStoreToken() {
  const token = JSON.parse(localStorage.getItem(LS.TOKEN) || null)
  const refreshToken = JSON.parse(
    localStorage.getItem(LS.REFRESH_TOKEN) || null
  )
  return {token, refreshToken}
}

export async function setStoreToken(token, refreshToken) {
  if (token && refreshToken) {
    store.commit("token", token)
    store.commit("refreshToken", refreshToken)
  }
  return true
}

export async function clearStoreToken() {
  store.commit("token", null)
  store.commit("refreshToken", null)
  console.log('API CLEAR STORE TOKEN')
}

// STORE PLATFORM MANAGEMENT
export function getStorePlatform() {
  return JSON.parse(localStorage.getItem(LS.PLATFORM) || null)
}

export async function setStorePlatform(platform) {
  if (platform) {
    if (platform.name) document.title = platform.name
    if (platform.theme && platform.theme.faviconUrl) {
      var link =
        document.getElementById("my-icon-placeholder") ||
        document.createElement("link")
      link.type = "image/x-icon"
      link.rel = "shortcut icon"
      link.href = platform.theme.faviconUrl
      document.getElementsByTagName("head")[0].appendChild(link)
    }
    if (platform.theme && platform.theme.manifestUrl) {
      const manifestLink =
        document.getElementById("my-manifest-placeholder") ||
        document.createElement("link")
      manifestLink.rel = "manifest"
      manifestLink.href = platform.theme.manifestUrl

      const cdnBaseUrl = platform.theme.manifestUrl.replace("manifest.webmanifest", "")

      const appleTouchIcon256 = document.getElementById("apple-touch-icon-256")
      appleTouchIcon256.href = `${cdnBaseUrl}icons/ios/256.png`
      const appleTouchIcon512 = document.getElementById("apple-touch-icon-512")
      appleTouchIcon512.href = `${cdnBaseUrl}icons/ios/512.png`
      const appleTouchIcon1024 = document.getElementById("apple-touch-icon-1024")
      appleTouchIcon1024.href = `${cdnBaseUrl}icons/ios/1024.png`
    }
    store.commit("platform", platform)
  } else {
    clearStorePlatform()
  }
  return await getStorePlatform()
}

export async function clearStorePlatform() {
  store.commit("platform", null)
}

// STORE USER MANAGEMENT
export async function getStoreUser() {
  const user =
    store && store.state && store.state.user ? store.state.user : null
  return user
}

export async function setStoreUser(user) {
  //console.log("API : SET STORE USER ", user, user.locale)
  if (user) {
    store.commit("user", user)
    await loadLanguageAsync(user.locale ? user.locale : navigator.language)
  } else {
    clearStoreUser()
  }
  return getStorePlatform()
}

export async function clearStoreUser() {
  console.log("clear user null, clear")
  store.commit("user", null)
  await loadLanguageAsync(navigator.language)
}

export async function clearSession() {
  await clearStoreUser()
  await clearStoreToken()
}

export async function logout() {
  // Logout from keycloak if previous token is set
  const {token} = await getStoreToken()

  // Logout from keycloak if previous token is set
  if (token) {
    try {
      await postFormKeycloakLogout()
    } catch (error) {
      console.error("[ERROR]", error)
    }
  }

  // Remove store refs
  await clearSession()
}

/**
 * APPLICATION
 */

// Get current session
export async function getPlatform(data, options) {
  const method = "get"
  const url = `/platform?url=${data.url}`
  return await ApiCommand(method, url, {}, options)
}

// Get theme
export async function getTheme(data, options) {
  const method = "get"
  const url = `/theme/${data.id}`
  return await ApiCommand(method, url, {}, options)
}

/**
 * SIGNUP
 */

// Check invitation code
export async function checkInvitationCode(data, options) {
  const method = "get"
  const url = `/signup/${data.code}`
  return await ApiCommand(method, url, {}, options)
}

export async function putFormSignup(data, options) {
  const method = "put"
  const url = `/signup/${data.code}`
  const newData = {
    birthdate: data.birthdate,
    country: data.country,
    email: data.email,
    firstname: data.firstname,
    gender: data.gender,
    lastname: data.lastname,
    mobile: data.mobile,
    newsletter: data.newsletter,
    password: data.password,
    // Data user not modified
    address: data.address,
    city: data.city,
    complement: data.complement,
    locale: data.locale,
    zipCode: data.zipCode,
    zoneId: data.zoneId
  }
  return await ApiCommand(method, url, newData, options)
}

export async function getFormSignupSms(data, options) {
  const method = "get"
  const url = `/signup/${data.code}/sms`
  return await ApiCommand(method, url, data, options)
}

export async function postFormSignupSms(data, options = {}) {
  const method = "post"
  const url = `/signup/${data.code}/sms`
  const newData = data.codeSms
  options.headers = {
    "Content-Type": "text/plain"
  }
  return await ApiCommand(method, url, newData, options)
}

export async function sendFormContact(data, options) {
  const method = "post"
  const url = `/contact/disconnected`
  const newData = {
    email: data.email,
    firstname: data.firstname,
    lastname: data.lastname,
    msg: data.message,
    subject: data.subject,
    language: navigator.language
  }
  console.log("data sended for contact", newData)
  return await ApiCommand(method, url, newData, options)
}

export async function sendFormActivationHelp(data, options) {
  return await sendFormContact(data, options)
}

export async function sendFormHelp(data, options) {
  const method = "post"
  const url = `/contact/connected`
  const newData = {
    email: data.email,
    firstname: data.firstname,
    lastname: data.lastname,
    msg: data.message,
    subject: data.subject
  }
  return await ApiCommand(method, url, newData, options)
}

export async function addBankInfo(data, options) {
  const method = "post"
  const url = `/card/${data.cardId}/addBankInfo/`
  const newData = {
    address: data.address,
    cp: data.cp,
    city: data.city,
    iban: data.iban,
    bic: data.bic
  }
  return await ApiCommand(method, url, newData, options)
}

/**
 * LOGIN
 */
// Request for keycloak
// export async function postFormKeycloak(data, options = {}) {
//   const method = "post"
//   const url = `${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/${process.env.VUE_APP_REALMS}/protocol/openid-connect/token`
//   options.headers = {
//     "Content-Type": "application/x-www-form-urlencoded"
//   }
//   const newData = qs.stringify({
//     username: data.email,
//     password: data.password,
//     grant_type: "password",
//     client_id: "myapp"
//   })
//
//   return await ApiCommand(method, url, newData, options)
// }

export async function postFormKeycloak(data, options = {}) {

  // const csrf = await axios.get(`${process.env.VUE_APP_BACKEND_LOGIN}`)
  // console.log("CSRF", csrf)
  const method = "post"
  const url = `${process.env.VUE_APP_BACKEND_LOGIN}/authz/login`
  options.headers = {
    "Content-Type": "application/json",
  }
  const newData = {
    username: data.email,
    password: data.password,
    rt: data.t,
  }
  return await ApiCommand(method, url, newData, options)
}

// export async function postFormKeycloakRefresh(options = {}) {
//   const method = "post"
//   const url = `${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/${process.env.VUE_APP_REALMS}/protocol/openid-connect/token`
//   options.headers = {
//     "Content-Type": "application/x-www-form-urlencoded"
//   }
//   const newData = qs.stringify({
//     grant_type: "refresh_token",
//     client_id: "myappp",
//     refresh_token: store.state.refreshToken
//   })
//   return await ApiCommand(method, url, newData, options)
// }
export async function postFormKeycloakRefresh(options = {}) {
  const method = "post"
  const url = `${process.env.VUE_APP_BACKEND_LOGIN}/authz/refresh`
  options.headers = {
    "Content-Type": "application/json",
  }
  const newData = qs.stringify({
    refresh_token: store.state.refreshToken
  })
  return await ApiCommand(method, url, newData, options)
}

// export async function postFormKeycloakLogout(options = {}) {
//   const method = "post"
//   const url = `${process.env.VUE_APP_KEYCLOAK_URL}/auth/realms/${process.env.VUE_APP_REALMS}/protocol/openid-connect/logout`
//   options.headers = {
//     "Content-Type": "application/x-www-form-urlencoded"
//   }
//   const newData = qs.stringify({
//     client_id: "backend-login-webapp",
//     refresh_token: store.state.refreshToken
//   })
//   return await ApiCommand(method, url, newData, options)
// }
export async function postFormKeycloakLogout(options = {}) {
  const method = "post"
  const url = `${process.env.VUE_APP_BACKEND_LOGIN}/authz/logout`
  options.headers = {
    "Content-Type": "application/json",
  }
  const newData = {
    refresh_token: store.state.refreshToken,
  }
  return await ApiCommand(method, url, newData, options)
}

// Request for user
export async function getUserApi(data, options = {}) {
  //console.log('get user api')
  const method = "get"
  const url = "/user"
  return await ApiCommand(method, url, data, options)
}

// Request for a login
export async function postFormLogin(data, options) {
  const method = "post"
  const url = "/login"
  return await ApiCommand(method, url, data, options)
}

export async function postFormPlasticCardActivation(data, options) {
  const method = "get"
  const url = `/signup/activation/mail/${data.email}`
  return await ApiCommand(method, url, data, options)
}

// Ask SMS code for new password
export async function forgotPasswordEmailSms(data, options) {
  const method = "post"
  const url = `/login/sms?email=${data.email}`
  return await ApiCommand(method, url, data, options)
}

// Verify SMS code for new password
export async function forgotPasswordSmsValidate(data, options) {
  const method = "post"
  const url = `/login/sms/validate?email=${data.email}&validationCode=${data.codeSms}`
  return await ApiCommand(method, url, data, options)
}

// Send new password
export async function forgotPasswordNewPwdValidate(data, options) {
  const method = "put"
  const url = "/login/pwd"
  const newData = {
    login: data.email,
    pwd: data.password
  }
  return await ApiCommand(method, url, newData, options)
}

/**
 * CARDS
 */

// Get list cards
export async function getCards(data, options) {
  const method = "get"
  const url = `/card/list`
  return await ApiCommand(method, url, data, options)
}

// Get current card
export async function getCard(data, options) {
  console.log("GET CARD")
  const method = "get"
  const url = `/card/${data.id}`
  return await ApiCommand(method, url, data, options)
}

export async function checkTopUpAllowed(data, options) {
  console.log("CHECK TOP UP AVAILABLITIY")
  const method = "post"
  const url = `/card/${data.id}/checkTopupAllowed`
  return await ApiCommand(method, url, data, options)
}

// Update user mobile
export async function changeStatusCard(data, options) {
  const method = "put"
  const url = `/card/${data.id}/status?status=${data.status}`
  return await ApiCommand(method, url, options)
}

// Update user mobile
export async function activePLasticCard(data, options) {
  const method = "put"
  const url = `/card/${data.id}/plasticCard/activate`
  return await ApiCommand(method, url, {cvv: data.cvv}, options)
}

// Get current card Wallester
export async function getWallesterCardDatas(data, options) {
  const method = "post"
  const url = `/card/${data.id}/encryptedCard`
  options.headers = {
    "Content-Type": "application/json"
  }
  // console.log('api request wallester', data, options, url)
  return await ApiCommand(method, url, data.publicKey, options)
}

// Get current card  Graphics
export async function getCardGraphics(data, options = {}) {
  const method = "get"
  const url = `/card/${data.id}/cardGraphicView`
  options.headers = {
    "Content-Type": "application/json"
  }
  // console.log('api request wallester', data, options, url)
  return await ApiCommand(method, url, data.publicKey, options)
}

// Get current card Rapy
export async function getRapydIframeDatas(data, options) {
  const method = "get"
  const url = `/card/${data.id}/iframeDatas`
  return await ApiCommand(method, url, data, options)
}

// Get current card unmasked
export async function getCardUnmasked(data, options) {
  const method = "get"
  const url = `/card/${data.id}/show`
  return await ApiCommand(method, url, data, options)
}

// Get card transactions
export async function getTransactions(data, options) {
  const method = "get"
  const url = `/card/${data.id}/transaction?offset=${data.offset}&size=${data.size}&order=${data.order}`
  return await ApiCommand(method, url, {}, options)
}

// Get card expirations
export async function getExpirations(data, options) {
  const method = "get"
  const url = `/card/${data.id}/transaction?offset=${data.offset}&size=${data.size}&type=Expiration&order=desc`
  return await ApiCommand(method, url, {}, options)
}

// Get card banners
export async function getCardBanners(data, options) {
  const method = "get"
  const url = `/card/${data.id}/banners?offset=${data.offset}&size=${data.size}&type=${data.type}`
  return await ApiCommand(method, url, {}, options)
}

// Fund a card with Stripe
export function postFundStripe(data, options) {
  const method = "get"
  const url = `/card/${data.id}/topup/${data.pi}/check`

  return ApiCommand(method, url, {}, options)
}

// Retrievce PaymentIntent with Stripe
export async function postPaymentIntent(data, options) {
  const method = "post"
  const url = `/card/${data.id}/topupSecret`
  const newData = {
    amount: data.amount
    // token: data.token
  }
  return await ApiCommand(method, url, newData, options)
}

export async function putBlockCard(id, options) {
  const method = "put"
  const url = `/card/${id}/block`
  return await ApiCommand(method, url, null, options)
}

export async function putUnblockCard(id, options) {
  const method = "put"
  const url = `/card/${id}/unblock`
  return await ApiCommand(method, url, null, options)
}


/**
 * USER ACCOUNT
 */

// Update user informations
export async function saveUserInformations(data, options) {
  const method = "put"
  const url = `/user`
  const user = store.state.user
  const newData = {
    birthdate: data.birthdate,
    country: data.country,
    firstname: data.firstname,
    gender: data.gender,
    lastname: data.lastname,
    email: "",
    mobile: "",
    password: "",
    address: user.address,
    city: user.city,
    complement: user.complement,
    locale: user.locale,
    newsletter: user.newsletter,
    zipCode: user.zipCode,
    zoneId: user.zoneId
  }
  store.commit("user", {
    ...user,
    birthdate: data.birthdate,
    country: data.country,
    firstname: data.firstname,
    gender: data.gender,
    lastname: data.lastname
  })
  return await ApiCommand(method, url, newData, options)
}

// Update user preferences
export async function saveUserPreferences(data, options) {
  const method = "put"
  const url = `/user`
  const user = store.state.user
  const newData = {
    locale: data.locale,
    newsletter: data.newsletter,
    zoneId: data.zoneId,
    email: "",
    mobile: "",
    password: "",
    address: user.address,
    birthdate: data.birthdate,
    city: user.city,
    complement: user.complement,
    country: user.country,
    firstname: user.firstname,
    gender: user.gender,
    lastname: user.lastname,
    zipCode: user.zipCode
  }
  store.commit("user", {
    ...user,
    locale: data.locale,
    newsletter: data.newsletter,
    zoneId: data.zoneId
  })
  return await ApiCommand(method, url, newData, options)
}

// Update user email
export async function saveUserEmail(data, options = {}) {
  const method = "put"
  const url = `/user/email`
  const user = store.state.user
  const newData = {
    mail: data.email,
    mobile: data.mobile,
    pwd: data.password
  }
  store.commit("user", {
    ...user,
    email: data.email
  })

  return await ApiCommand(
    method,
    url,
    newData,
    options
  ) /* .then(
    // On affiche un message avec la valeur
    function (val) {
      return val
    }).catch(
    // Promesse rejetée
    function (val) {
      // if (val.response.status === 400) return "errors.passwordIncorrect"
      return val
    }) */
}

// Update user mobile
export async function saveUserMobile(data, options) {
  const method = "put"
  const url = `/user/mobile`
  const newData = {
    mobile: data.mobile,
    pwd: data.password
  }
  return await ApiCommand(method, url, newData, options)
}

// Update user mobile
export async function validCodeMobile(data, options) {
  const method = "post"
  const url = `/user/mobile/validate`
  const user = store.state.user
  const newData = {
    code: data.codeSMS,
    mobile: data.mobile
  }
  store.commit("user", {
    ...user,
    mobile: data.mobile
  })
  return await ApiCommand(method, url, newData, options)
}

// Update user password
export async function saveUserPassword(data, options) {
  const method = "put"
  const url = `/user/pwd`
  const newData = {
    newpwd: data.newPassword,
    oldpwd: data.password
  }
  return await ApiCommand(method, url, newData, options)
}

export async function loginTrustToken(token) {
  const method = "post"
  const url = process.env.VUE_APP_EXTERNAL_LOGIN;
  const options = {
    headers: {
      token: token
    }
  }
  //console.log('headers ', options.headers)

  return await ApiCommand(method, url, {}, options)
}


// Retrievce PaymentIntent with Stripe
export async function simulatePayment(data, options) {
  console.log('simulate payment api')
  const method = "post"
  const url = `/card/simulate`
  return await ApiCommand(method, url, data, options)
}
