<template>
  <GetterWrapper id="security" :api-load="apiLoad" @data="onData">
    <div class="security row align-center">
      <Popup v-if="showPolitics" hasClose @close="togglePolitics()">
        <ViewBlocs :blocs="$tm('politics.blocs')" section="politics" />
      </Popup>

      <ViewHeader :hasBack="true" urlBack="account" :title="$t('account.actions.security')"/>
      <div class="columns small-12 medium-10 large-8">
        <!--ViewDetails  /-->

        <FormEmail />
        <FormMobile />
        <FormPassword />

        <fieldset>
          <legend>&nbsp;</legend>
          <div class="row align-center">
            <label class="columns small-12 medium-7 text-justify legal-link">
              {{ $t('account.formIndications') + ' ' + $t('form.politics.begin')}}
              <span
                @click="togglePolitics()"
              >{{ $t('form.politics.click')}}</span>
              {{ $t('form.politics.end')}}
            </label>
          </div>
        </fieldset>
      </div>
    </div>
  </GetterWrapper>
</template>

<script src="./security.js"></script>
