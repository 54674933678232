<template>
  <!-- Request cards data -->
  <div class="statement row align-center">
    <div v-if="loading">
      <Spinner :alt="$t('form.loading')"/>
    </div>
    <div v-else class="columns">
      <div class="budget-items-info large-up-3 microcopies padding-1" v-if="budgets.length>1">
        {{$t('card.budgets.info')}} <!--<span class="icon-help"></span>-->
      </div>
      <div class="budget-items large-up-3 small-up-2">
        <budget-item v-for="(b,i) in budgets" :budget=b :key=i />
      </div>
      <div v-if="(!transactions || transactions.length === 0)" class="row">
        <div class="columns small-12 text-center">{{ $t("card.statement.empty") }}</div>
      </div>

      <!-- Passed transactions -->
      <div v-if="transactions && transactions.length > 0" class="row">
        <div class="columns small-12">
          <span class="microcopies">{{ $t("card.statement.passed") }}</span>
          <ItemTransaction
            v-for="(transaction, index) in transactions"
            :key="index"
            :isOpenned="indexPassed === index"
            :transaction="transaction"
            @open="openPassed(index)"
          />
        </div>
      </div>

      <!-- Actions texts -->
      <div
        v-if="(transactions.length > 0)"
        class="row small-up-1 medium-up-2 large-up-2 align-center"
      >
        <button
          v-if="hasMoreTransactions"
          ref="addToHome"
          id="add-to-home"
          class="button mx-auto"
          v-html="$t('card.statement.more')"
          @click="addTransactions"
        >
        </button>
      </div>
    </div>
  </div>
</template>

<script src="./statement.js"></script>
<style scoped lang="scss">
.statement {
  @import '~@/styles/base';

  .budget-items-info {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
  .budget-items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    padding-bottom: 30px;
  }

  .icon-help {
    background-position: center;
    background-image: url($cdn-url + '/help-filled.svg');
    background-repeat: no-repeat;
    background-size: 14px 14px;;
    width: 14px;
    height: 14px;

  }
}

</style>
