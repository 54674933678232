import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import {
  getCard,
} from '@/store/api'
import router from "@/router";
import ViewHeader from "@/components/view-header/view-header.vue";

export default {
  name: "HowToUse",
  components: {
    ViewHeader,
    GetterWrapper,
  },
  props: {},
  computed: {},
  data() {
    return {
      apiLoad: [{
        api: getCard,
        data: {
          id: this.$route.params.id
        }
      },
      ],
      card: {},
    }
  },
  methods: {
    goToFaq() {
      router.push({
        name: 'card-faq',
        params: {id: this.card.id}
      })
    },
    onData(data) {
      this.card = data[0]
      this.card.masked = data[1]
    },
  }
}
