<template>
  <!-- Request cards data -->
  <div ref="menuWrapper">
    <div class="card-item-menu">
      <div class="card-item-menu-button-container">
        <a href="#" @click="closeOrOpen()" class="card-item-menu-button"></a>
      </div>
    </div>
    <div ref="cardMenuElement" :id="`cardMenuElement-${card.id}`" class="card-item-menu">
      <div v-if="menuOpened" class="card-item-menu-container">
        <a href="#" @click="closeOrOpen()" class="card-item-menu-button open"></a>
        <div id="cnt-btn-howto">
          <router-link :to="{name:'card-item-how-to-use',params:{id: card.id}}">
            {{ $t('card.actions.howToUse') }}
          </router-link>
        </div>
        <div v-if="donateEnable" id="cnt-btn-donate">
          <router-link :to="{name:'card-item-donate',params:{id: card.id}}">
            {{ $t('card.actions.donate') }}
          </router-link>
        </div>
        <div v-if="card.program.stripeKey != null &&
              card.program.stripeKey.length &&
              topupEnable && (!isMaintenanceMode ||card.amount !== 0 ||card.statusAcceptationCard === 'ACCEPTED' ||card.program.cardEnabled)"
             id="cnt-btn-balance">
          <router-link
            id="btn-balance"
            :to="{name:'card-item-balance', params:{ id: card.id }}">
            {{ $t('card.actions.balance') }}
          </router-link>
        </div>
        <div v-if="!isMaintenanceMode" id="cnt-btn-rewards">
          <router-link
            id="btn-rewards"
            :to="{name:'card-item-rewards', params:{ id: card.id }}">
            {{ $t('card.actions.rewards') }}
          </router-link>
        </div>
        <div v-if="!isMaintenanceMode" id="cnt-btn-xpay">
          <router-link
            id="btn-xpay"
            :to="{name:'card-item-xpay', params:{ id: card.id }}">
            {{ $t('card.actions.xpay') }}
          </router-link>
        </div>
        <div v-if="!isMaintenanceMode" id="cnt-btn-faq">
          <router-link
            id="btn-faq"
            :to="{name:'card-faq', params:{ id: card.id }}">
            {{ $t('card.actions.faq') }}
          </router-link>
        </div>
        <div v-if="!isMaintenanceMode" id="cnt-btn-tos">
          <router-link
            id="btn-tos"
            :to="{name:'card-item-tcos', params:{ id: card.id }}">
            {{ $t('card.actions.tos') }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./card-item-menu.js"></script>
<style lang="scss">
@import "~@/styles/theme";
@import "~@/styles/mixins";

.card-item-menu .card-item-menu-button-container {
  z-index: 1000;

  //.card-item-menu-button-container {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  //background: $white;
  //box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  width: 32px;
  height: 32px;

  .card-item-menu-button {
    content: url($card-menu);
    background-size: contain;

    &.open {
      content: url($card-menu);
      transform: rotate(90deg);
    }
  }
}

@include small-only {
  .card-menu-open .card-item-menu .card-item-menu-button-container {
    display: none;
  }
}

.card-item-menu {
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  z-index: 1000;
  width: 32px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .card-item-menu-button {
    content: url($card-menu);
    background-size: contain;

    &.open {
      content: url($card-menu);
      transform: rotate(90deg);
    }
  }
}

.card-menu-open .card-item-menu {
  background: none;
  box-shadow: none;
}

.card-item-menu-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  z-index: 1000;
  width: 253px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @include small-only {
    position: relative;
    top: -17px;
    left: -218px;
    right: 0;
    border-radius: 10px;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
    z-index: 1000;
    width: 253px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }


  div {
    margin-left: 12px;
    margin-right: 12px;
    margin-top: 12px;

    &:first-of-type {
      margin-top: 0; /* Remplacez 20px par la valeur souhaitée */
    }

    a {
      color: $black;
      font-size: 13px;
      font-weight: bold;
      font-family: 'Plus Jakarta Sans', sans-serif;
    }
  }
}
</style>
