<template>
  <div class="cgu row align-center">
    <ViewHeader :hasBack="true" :title="$t('tcos.title')"/>
    <div class="columns small-12 medium-10 large-8">
      <ViewBlocs :blocs="tos" section="cgu" />
    </div>
  </div>
</template>

<script src="./tcos.js"></script>
