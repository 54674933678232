<template>
  <div class="help row align-center" :class="{'fl-type' : isFLType}">
    <Popup v-if="showPolitics" hasClose @close="togglePolitics()">
      <ViewBlocs :blocs="$tm('politics.blocs')" section="politics"/>
    </Popup>

    <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
      <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
      <p class="popup-valid-text text-center">{{ $t('help.moreHelp.submitOk') }}</p>
    </Popup>

    <ViewHeader :hasBack="true" :title="$t('faq.title')" :subtitle="$t('help.subtitle')"/>
    <div class="columns small-12 medium-10 large-8">
      <div v-for="(bloc, index) in blocs" :key="'help-bloc-' + index" class="help-bloc">
        <div v-if="bloc.title!==''">
          <ActionText
            class="help-subject"
            :icon="'sofont-arrow-down' + (currentHelp === index ? ' is-up' : '')"
            :isTitle="true"
            :text="bloc.title"
            v-on:action="openHelp(index)"
          />
          <p
            v-if="(!isFLType && currentHelp === index) || (isFLType && currentHelp === index && (currentHelp !== 0 && currentHelp !== 5))"
            v-html="bloc.text"></p>
          <p v-if="currentHelp === index && isFLType && index === 0">Votre carte cadeau est exclusivement valable dans
            tous les magasins du centre commercial Buld'air Shopping Centre de Vedène.</p>
          <p v-if="currentHelp === index && isFLType && index === 5">Oui, vous pouvez compléter le solde de votre carte
            cadeau en utilisant une autre moyen de paiement accepté en caisse. Adressez-vous à un conseiller du magasin
            concerné au préalable.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script src="./faq.js"></script>
