import CardItem from "@/components/card-item/card-item.vue"
import GetterWrapper from "@/components/getter-wrapper/getter-wrapper.vue"
import InputWrapper from "@/components/input-wrapper/input-wrapper.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import Popup from '@/components/popup/popup.vue'
import ViewBlocs from '@/components/view-blocs/view-blocs.vue'
import {getCards, changeStatusCard} from '@/store/api'
import $ from "jquery"
import slick from 'slick-carousel'
import CardItemWallester from "@/components/card-item-wallester/card-item-wallester.vue"
import store from "@/store"
import {nextTick} from 'vue'
import StatementBudgets from "@/views/home/statement/statement.vue";
import {EventBus} from "@/event-bus";
import {changeNumberFormats} from "@/helpers/i18n";
import CardItemMenu from "@/components/card-item-menu/card-item-menu.vue";
import CardItemActions from "@/components/card-item-actions/card-item-actions.vue";

export default {
  name: 'Home',
  components: {
    CardItemActions,
    CardItemMenu,
    StatementBudgets,
    CardItemWallester, CardItem, GetterWrapper, ViewHeader, ViewDetails, Popup, InputWrapper, ViewBlocs
  },
  props: {},
  computed: {},
  data() {
    return {
      active: false,
      cardMenuIsOpen: false,
      activeCard: {},
      activeCurrency: this.$i18n.numberFormats[this.$i18n.locale].currency.currency,
      apiLoad: [
        {
          api: getCards,
        }
      ],
      tos: [],
      cards: [],
      store: this.$store.state,
      showPopupAcceptationCard: false,
      showPopupRefuseCard: false,
      acceptTermsCheckbox: false,
      acceptPopupButton: true,
      refusePopupButton: true,
      initialSwipeX: null,
      initialSwipeY: null,
      carousel: null,
      carouselOptions: {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        centerMode: window.innerWidth > 768,
        centerPadding: window.innerWidth < 768 ? '10px' : '50px',
        infinite: false,
        rows: 0,
      }
    }
  },
  created: function () { /* possible init API call */
  },
  mounted: function () { /* DOM accessible via this.$el */
    this.addClassAppBackground('bg-app-home')
    //console.log('window.client', window.innerWidth)
    EventBus.on('showCardMenu', (card) => {
      this.showCardMenu(card)
    })
    EventBus.on('closeCardMenu', (card) => {
      this.closeCardMenu(card)
    })
  },
  unmounted() {
    this.removeClassAppBackground('bg-app-home')
    EventBus.off('showCardMenu')
    EventBus.off('closeCardMenu')
  },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */
  },
  methods: {
    addClassAppBackground(color) {
      document.body.classList.add(color);
    },
    removeClassAppBackground(color) {
      document.body.classList.remove(color);
    },
    onData([data]) {
      this.cards = data || []
      store.commit('setCards', this.cards)
      store.commit('setProgId', null)

      // Wait next tick to be sure than rendering is done
      nextTick(() => {
        console.log('this cards ', this.cards)
        this.initCarousel()
        this.initEventCarousel()
        if (store.state.currentCard && store.state.currentCard.id) {
          let cardIndex = this.cards.findIndex(c => c.id === store.state.currentCard.id)
          console.log(`Card index : ${cardIndex}`)
          this.setActiveCard(cardIndex)
          this.slideTo(cardIndex)
        } else {
          this.setActiveCard(0)
        }
        window.addEventListener('resize', this.refreshCarousel)
      })
    },

    initCarousel() {
      this.carousel = $(this.$refs.cardsCarousel).slick(this.carouselOptions)
    },
    initEventCarousel() {
      this.carousel.on('beforeChange', (evt, slick, currentSlide, nextSlide) => {
        this.setActiveCard(nextSlide)
      })
      this.carousel.on('afterChange', (evt, slick, currentSlide, nextSlide) => {
        this.cards.forEach((cardTocheck) => {
          if (cardTocheck.id !== this.activeCard.id) {
            const menuElement = document.getElementById('menu-' + cardTocheck.id);
            if (menuElement)
              menuElement.style.display = 'none'
          }
        })
      })
    },
    slideTo(index) {
      if (this.carousel)
        this.carousel.slick('slickGoTo', index)
    },
    setActiveCard(index) {
      const card = this.cards[index]
      if (!card) return
      this.active = true
      this.activeCard = card
      store.commit("setCurrentCard", this.activeCard)
      if (this.$i18n && this.$i18n.numberFormats) {
        const numberFormat = this.$i18n.numberFormats[this.$i18n.locale]
        numberFormat.currency.currency = this.activeCard.program.currency
        changeNumberFormats(this.$i18n.locale, numberFormat)
      }
      this.activeCurrency = this.activeCard.program.currency
      EventBus.emit('cardSelected', this.activeCard)
    },
    refreshCarousel() {
      this.carousel.slick("getSlick").refresh()
      this.carousel.slick("slickSetOption", 'centerPadding', window.innerWidth < 768 ? '20px' : '50px',)
    },
    togglePopupAcceptationCard() {
      this.showPopupAcceptationCard = !this.showPopupAcceptationCard
    },
    togglePopupRefuseCard() {
      this.showPopupRefuseCard = !this.showPopupRefuseCard
    },
    acceptCard() {
      this.acceptPopupButton = false
      changeStatusCard({
        id: this.activeCard.id,
        status: 'ACCEPTED'
      }).then((res) => {
        this.activeCard = res.data
        this.togglePopupAcceptationCard()
        this.acceptPopupButton = true
        this.acceptTermsCheckbox = false
        this.$router.push({name: 'card-item', params: {id: this.activeCard.id}})
      })
    },
    refuseCard() {
      this.refusePopupButton = false
      changeStatusCard({
        id: this.activeCard.id,
        status: 'REFUSED'
      }).then(() => {
        this.cards.forEach((card) => {
          if (card.id === this.activeCard.id) {
            card.statusAcceptationCard = 'REFUSED'
          }
        })
        this.activeCard.statusAcceptationCard = 'REFUSED'
        this.togglePopupRefuseCard()
        this.refusePopupButton = true
      })
    },
    showCardMenu(card) {
      this.cardMenuIsOpen = true;
      const menuElement = document.getElementById('cardMenuElement-' + card.id);
      const targetElement = document.getElementById('cardMenuContainer-' + card.id);
      if (!this.$refs.cardPlaceholder) return;
      this.$refs.cardPlaceholder.filter(h => h.id !== `card-carousel-${card.id}`)
        .forEach(e => {
          e.firstChild.style.display = 'none' //inline-block
        }); // Référence à la div cible
      // if (menuElement && targetElement) {
      //   targetElement.appendChild(menuElement); // Déplace la div dans la div cible
      // } else {
      //   console.error("pas bon ",menuElement, targetElement)
      // }
    },
    closeCardMenu(card) {
      this.cardMenuIsOpen = false;
      if (!this.$refs.cardPlaceholder) return;
      this.$refs.cardPlaceholder
        .forEach(e => {
          e.firstChild.style.display = 'inline-block'
        });
    }
  }
}
