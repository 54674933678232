<template>
  <div class="informations row align-center">
    <Popup v-if="showPolitics" hasClose @close="togglePolitics()">
      <ViewBlocs :blocs="$tm('politics.blocs')" section="politics"/>
    </Popup>

    <ViewHeader :hasBack="true" urlBack="account" :title="$t('account.actions.informations')"/>
    <div class="columns small-12 medium-10 large-8">
      <!--ViewDetails  /-->

      <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
        <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
        <p class="popup-valid-text text-center">{{ $t('forms.informations.sendOk') }}</p>
      </Popup>

      <FormWrapper
        forceId="informations"
        :api="saveUserInformations.bind(this, this.form)"
        :btnClass="showForm ? '' : 'is-hidden'"
        :btnDisabled="!isModified"
        :btnText="`${$t('form.save')}`"
        :validation="v$.form"
        @cancel="this.toggleForm"
        @submitted="onSubmitted"
        @error="onError"
      >
        <fieldset>
          <ActionText
            :class="showForm ? 'btn-cancel' : 'btn-edit'"
            :icon="showForm ? 'sofont-cross-close' : 'sofont-edit'"
            :text="showForm ? $t('form.cancel') : $t('form.edit')"
            @action="toggleForm()"
          />
          <div class="row align-center">
            <InputWrapper isRequired class="columns small-12 medium-7" :validation-path="'form.gender'"
                          :isInvalid="v$.form.gender.$invalid">
              <input
                id="genderFemale"
                name="sex"
                type="radio"
                value="female"
                v-model.lazy="v$.form.gender.$model"
                :disabled="!showForm"
                @input="update()"
              />
              <label for="genderFemale">{{ `${$t('form.genderFemale')}` }}</label>
              <input
                id="genderMale"
                name="sex"
                type="radio"
                value="male"
                v-model.lazy="v$.form.gender.$model"
                :disabled="!showForm"
                @input="update()"
              />
              <label for="genderMale">{{ `${$t('form.genderMale')}` }}</label>
            </InputWrapper>

            <InputWrapper :label="`${$t('form.firstname')}`" class="columns small-12 medium-7"
                          :validation-path="'form.firstname'" :isInvalid="v$.form.firstname.$invalid">
              <input
                name="firstname"
                type="text"
                :placeholder="$t('form.firstname')"
                v-model.trim.lazy="v$.form.firstname.$model"
                :disabled="!showForm"
                required
                @focus="update()"
                @input="forceUpper($event, form, 'firstname')"
              />
            </InputWrapper>

            <InputWrapper :label="`${$t('form.lastname')}`" class="columns small-12 medium-7"
                          :validation-path="'form.lastname'"
                          :isInvalid="v$.form.lastname.$invalid">
              <input
                name="lastname"
                type="text"
                :placeholder="$t('form.lastname')"
                v-model.trim.lazy="v$.form.lastname.$model"
                :disabled="!showForm"
                required
                @focus="update()"
                @input="forceUpper($event, form, 'lastname')"
              />
            </InputWrapper>

            <InputWrapper
              :label="`${$t('form.birthdate')}`"
              class="columns small-12 medium-7"
              :minAgeRequiredMessage="`${$t('form.error.minAgeRequired.account')}`"
              :dateNoExistMessage="`${$t('form.error.dateNoExist')}`"
              :validation-path="'form.birthdate'"
              :isInvalid="v$.form.birthdate.$invalid"
            >
              <InputBirthdate name="birthdate"
                              v-model:birthdate.lazy="v$.form.birthdate.$model"
                              required
                              @input="update()"
                              :disabled="!showForm"/>
            </InputWrapper>

            <InputWrapper :label="`${$t('form.country')}`" class="columns small-12 medium-7"
                          :validation-path="'form.country'"
                          :isInvalid="v$.form.country.$invalid"
            >
              <select
                name="country"
                v-model.lazy="v$.form.country.$model"
                :disabled="!showForm || (showForm && tinSupported)"
                required
                @focus="update()"
              >
                <option
                  v-for="country in countries"
                  :key="country.code"
                  :value="country.code"
                >{{ uppercase(country.label) }}
                </option>
              </select>
            </InputWrapper>
            <!--InputWrapper :label="`${$t('form.tin')}`" class="columns small-12 medium-7" :validation-path="'form.taxeIdentificationNumber'" >
              <input
                name="tin"
                type="text"
                :pattern="tinPattern"
                :placeholder="$t('form.tin')"
                v-model.trim.lazy="v$.form.taxeIdentificationNumber.$model"
                :disabled="!showForm"

                @focus="update()"
                @input="forceUpper($event, form, 'taxeIdentificationNumber')"
              />
            </!--InputWrapper-->

          </div>
        </fieldset>
      </FormWrapper>

      <fieldset>
        <legend>&nbsp;</legend>
        <div class="row align-center">
          <label class="columns small-12 medium-7 text-justify legal-link">
            {{ $t('account.formIndications') + ' ' + $t('form.politics.begin') }}
            <span
              @click="togglePolitics()"
            >{{ $t('form.politics.click') }}</span>
            {{ $t('form.politics.end') }}
          </label>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script src="./informations.js"></script>
