<template>
  <div>
    <!-- Display the list of available cards -->
    <GetterWrapper id="home" :api-load="apiLoad" @data="onData" class="columns cards-list">
      <div :class="'home row align-center'+(cardMenuIsOpen===true?' card-menu-open':'')">
        <!-- Cards Header -->
        <ViewHeader v-if="activeCard.id"
                    :title="$t('card.home.your-card',{cardName:activeCard.program.cardName})"
        />

        <div class="columns small-12 medium-10 large-8">
          <!--          <ViewDetails v-if="activeCard"-->
          <!--                       :subtitle="activeCard.program ? $t('card.card',  {value : activeCard.program.cardName}) : ''"-->
          <!--          />-->
          <!-- Display Cards -->
          <div class="cards-carousel" ref="carousel">
            <div class="cards-viewport" ref="cardsCarousel" id="cardsCarousel">
              <div
                class="card-placeholder"
                ref="cardPlaceholder"
                v-for="(card, index) in cards"
                :id="`card-carousel-${card.id}`"
                :key="card.id"
                @click="slideTo(index, card)"
              >
                <CardItemWallester
                  v-if="card.program.monetician == 'WALLESTER' && card.program.cardEnabled == true && !actionOnCard"
                  :class="activeCard.id === card.id ? 'text-center' : 'text-center'"
                  :style="activeCard.id === card.id ? 'z-index:1000' : 'z-index:1'"
                  @error="cardShown = false"
                  :active="activeCard.id === card.id "
                  :flipEnabled="activeCard.id === card.id"
                  :card="card"
                />
                <CardItemWallester
                  v-else-if="card.program.monetician == 'WALLESTER' && card.program.cardEnabled == true && actionOnCard"
                  :class="activeCard.id === card.id ? 'text-center' : 'text-center'"
                  :style="activeCard.id === card.id ? 'z-index:1000' : 'z-index:1'"
                  :active="activeCard.id === card.id "
                  :flipEnabled="false"
                  @error="cardShown = false"
                  :card="card"
                  :action="card.cardBlocked?'blocked':null"
                />
                <div v-if="activeCard.id === card.id" :id="`cardMenuContainer-${card.id}`"></div>
              </div>
            </div>
            <div v-if="activeCard.statusAcceptationCard === 'WAITING'" class="card-item__footer" style="display: flex; justify-content: center;">
              <CardItemActions :card="activeCard" style="width: auto;"/>
            </div>
          </div>

          <div class="active-card-transactions-budgets align-center">
            <statementBudgets/>
          </div>
        </div>
      </div>
    </GetterWrapper>
  </div>
</template>

<script src="./home.js"></script>
<style scoped lang="scss">
@import '~@/styles/base';
@import '~@/styles/mixins';

.home {
  .view-details {
    margin-bottom: 15px;
  }

  .view-header {
    margin-top: 20px;
  }

  .view-row {
    @include small-only {
      max-width: 100%;
    }
  }
}
</style>
