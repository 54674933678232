<template>
  <div :class="`budget-item ${this.budget.type !== 'TOP_UP'?'clickable':''}`" @click="goToStore">
    <div class="header">
      <RoundIcon font-size="16px" font-color="dark-blue" class="detail-icon" background="green-20"
                 :icon="`sofont-budget-${getTypeBudget(budget.type)}`"/>
      <span class="name" v-html="$t('card.budget-'+(getTypeBudget(budget.type)))"></span>
    </div>
    <div class="amount">
      {{ $n(budget.amount, 'currency') }}
    </div>
    <div class="footer">
      <router-link
        v-if="budget.type!=='TOP_UP'"
        id="btn-store"
        :to="{name:'card-item-store', params:{ id: budget.id}}">
        {{ $t('card.actions.store') }}
      </router-link>
      <span v-else class="expiration-text microcopies">
        {{ $t('card.budget.expire', {value: numberDaysToExpire}) }}
      </span>
    </div>
  </div>
</template>

<script src="./budget-item.js"></script>
<style scoped lang="scss" src="./budget-item.scss"></style>
