<template>
  <div :name="name" :class="'input-birthdate '+ (selectedDateToString ? 'valid' : 'invalid')">
    <!--input v-show="isMobile && type == 'text'" ref="input-date-text"  type="text" @click="onTextClick"  :placeholder="placeholder" />
<input v-show="!isMobile || (isMobile && type == 'date')" class="input-date-simple" ref="input-date" :name="name" :disabled="disabled" :value="form.value" type="date" @change="onInputChange"   -->
    <select
      :class="'form-select input-birthdate-day '+ (!form.displayedValue.d ? 'empty ' : '') + (isValidDay ? ' is-valid' : 'is-invalid')"
      ref="ib-day" @change="onInputChange($event, 'd')"
      id="input-birthdate-day"
      v-model="form.displayedValue.d"
      :required="required"
      :disabled="disabled" :name="name + '-day'">
      <option disabled v-if="!form.displayedValue.d" value="">{{ $t('form.birthdateDay') }}</option>
      <option v-for="day in daysNum" :key="'day-'+day" :value="day">{{ formatDisplayedNumber(day) }}</option>

    </select>
    <select
      :class="'form-select input-birthdate-month '+ (!form.displayedValue.m ? 'empty ' : '') + (isValidMonth? ' is-valid' : 'is-invalid')"
      ref="ib-month" @change="onInputChange($event, 'm')"
      v-model="form.displayedValue.m"
      :required="required"
      :disabled="disabled"
      id="input-birthdate-month"
      :name="name + '-month'">
      <option disabled v-if="!form.displayedValue.m" value="">{{ $t('form.birthdateMonth') }}</option>
      <option v-for="month in monthsNum" :key="'month-'+month" :value="month">{{ formatDisplayedNumber(month) }}
      </option>

    </select>
    <select
      :class="'form-select input-birthdate-year '+ (!form.displayedValue.y ? 'empty ' : '') + (isValidYear ? ' is-valid' : 'is-invalid')"
      ref="ib-year" @change="onInputChange($event, 'y')"
      id="input-birthdate-year"
      v-model="form.displayedValue.y"
      :required="required"
      :disabled="disabled"
      :name="name + '-year'">
      <option disabled v-if="!form.displayedValue.y" value="">{{ $t('form.birthdateYear') }}</option>
      <option v-for="year, index in displayedYearsRange(minYear, maxYear)" :key="'year-'+index" :value="year">
        {{ year }}
      </option>
    </select>
  </div>

</template>

<script src="./input-birthdate.js"></script>
<style lang="scss" src="./input-birthdate.scss"></style>
