<template>
  <div class="item-transaction row">
    <div id="transaction-left" class="column small-3 large-3 transaction-left">
      <span>{{ $d(transaction.date, 'short') }}</span>
      <span>{{ $d(transaction.date, 'time') }}</span>
    </div>
    <div id="transaction-center" class="column small-6 large-6 transaction-center">
      <span class="label">{{ label }}</span>
      <span class="budget-icons">
        <i v-for="(budget, index) in transaction.budgets" :key="'kit-ui-bloc-' + index"
           :class="`sofont sofont-budget-${budget?.toLowerCase()} budget-icon`" style="font-size: 16px"
           :title="transaction.sens === 'DEBIT' ? $t('budget.info.conso-'+budget?.toLowerCase()) : ''"
        />
      </span>

    </div>
    <div id="transaction-right" class="column small-3 large-3 transaction-right">
        <span v-if="transaction.sens === 'CREDIT'"
              :class="`transaction-amount positive ${transaction.status === 'ERROR'?'error':''}`">
          <b>+{{ $n(transaction.amount, 'currency') }}</b>
        </span>
      <span v-if="transaction.sens === 'DEBIT'"
            :class="`transaction-amount negative ${['ERROR','RELEASED'].includes(transaction.status)?'error':''}`">
          <b>{{ $n((transaction.amount * -1), 'currency') }}</b>
        </span>
      <span v-if="transaction.status === 'PENDING'" class="transaction-status microcopies">
        {{ $t('transactions.pending') }}
      </span>
      <span v-else-if="transaction.status === 'NORMAL'" class="transaction-status microcopies">
        {{ $t('transactions.validated') }}
      </span>
      <span v-else-if="transaction.status === 'VALIDATED'" class="transaction-status microcopies">
        {{ $t('transactions.validated') }}
      </span>
      <span v-else-if="transaction.status === 'RELEASED'" class="transaction-status microcopies">
        {{ $t('transactions.released') }}
      </span>
      <span v-else-if="transaction.status === 'ERROR'" class="transaction-status microcopies">
        <span v-if="getError(transaction) === 'NOT_ENOUGH_FUND'">{{ $t('transactions.error.not-enough-fund') }}</span>
        <span v-else-if="getError(transaction) === 'MERCHANT_NOT_AUTHORIZED'">{{
            $t('transactions.error.merchant-not-authorized')
          }}</span>
        <span v-else>{{ $t('transactions.error.undefined') }}</span>

      </span>
    </div>
  </div>
</template>

<script src="./item-transaction.js"></script>
