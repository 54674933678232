import { required } from '@vuelidate/validators'
import ActionText from "@/components/action-text/action-text.vue"
import FormWrapper from '@/components/form-wrapper/form-wrapper.vue'
import InputWrapper from '@/components/input-wrapper/input-wrapper.vue'
import Popup from '@/components/popup/popup.vue'
import ViewBlocs from "@/components/view-blocs/view-blocs.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import ViewDetails from "@/components/view-details/view-details.vue"
import { useVuelidate } from '@vuelidate/core'
import { sendFormHelp } from '@/store/api'

export default {
  name: 'Faq',
  components: {
    ActionText,
    FormWrapper,
    InputWrapper,
    Popup,
    ViewBlocs,
    ViewHeader,
    ViewDetails
  },
  props: {},
  computed: {
    logged () {
      if (this.$store.state.token) {
        return (this.$store.state.user ? this.$store.state.user.email !== undefined : false)
      }
      return false
    },
  },
  data () {
    return {
      v$: useVuelidate(),
      sendFormHelp,
      blocs: Array.isArray(this.$tm('help.blocs')) ? this.$tm('help.blocs') : [],
      currentHelp: -1,
      displayForm: false,
      form: {
        firstname: this.$store.state.user?.firstname,
        lastname: this.$store.state.user?.lastname,
        email: this.$store.state.user?.email,
        subject: '',
        message: ''
      },
      loadLoading: false,
      showMoreHelp: false,
      showPolitics: false,
      showPopupOk: false,
      subjects: Array.isArray(this.$tm('help.moreHelp.subjects')) ? this.$tm('help.moreHelp.subjects') : [],
      isFLType: false
    }
  },
  validations: {
    form: {
      subject: { required },
      message: { required }
    },
  },
  beforeRouteEnter(to, from, next){


    next((vm)=>{
      console.log('help enter', to,  to.params)
      if(to.params.FLType){
        vm.isFLType = true
      }
    });

  },
  created: function () { },
  mounted: function () { },
  beforeUnmount: function () { /* clean all non-Vue listeners/objects */ },
  methods: {
    openHelp (index) {

      this.currentHelp = (this.currentHelp === index) ? -1 : index
      console.log('currentHelp', this.currentHelp)
    },
    toggleMoreHelp () {
      this.showMoreHelp = !this.showMoreHelp
    },
    togglePolitics () {
      this.showPolitics = !this.showPolitics
    },
    openPopupOk () {
      this.showPopupOk = true
    },
    closePopupOk () {
      this.showPopupOk = false
      this.form = {
        firstname: this.$store.state.user?.firstname,
        lastname: this.$store.state.user?.lastname,
        email: this.$store.state.user?.email,
        subject: '',
        message: ''
      }
      this.v$.$reset()
    },
    async onSubmitted () {
      this.openPopupOk()
    },
    onError (error) {
      console.error('[ERROR]', error)
    },
  }
}
