import {getWallesterCardDatas} from "@/store/api"
import Spinner from "@/components/spinner/spinner.vue"
import {getDateToExpirationFormat} from "@/helpers/date.js"
import CardContentWallester from "./card-content-wallester/card-content-wallester.vue"
import CardItemActions from "@/components/card-item-actions/card-item-actions.vue";

import {EventBus} from "@/event-bus";

export default {
  name: "CardItemWallester",
  components: {CardItemActions, Spinner, CardContentWallester},
  props: {
    card: {
      type: Object, default: () => {
      }
    },
    flipEnabled: {
      type: Boolean, default: true
    },
    active: {
      type: Boolean, default: false
    }
  },
  data() {
    return {
      loading: false,
      displayedCard: {
        number: "",
        cvv: "",
        expiration: "",
        secret: null
      },
      keys: {
        publicKey: {},
        privateKey: {}
      },
      flipped: false,
      copyAlert: false,
      copyAlertTimeout: 3000,
      showHelp: false
    }
  },
  created() {
  },
  computed: {},

  async mounted() {
    this.loading = true

    EventBus.on('card-shown', async (cardIdToShow) => { //
      if (cardIdToShow === this.card.id) {
        this.loading = true
        await this.setCardsInfos()
        this.flipped = true
        this.loading = false
      }
    })
    EventBus.on('card-hide', (cardIdToHide) => { //
      if (cardIdToHide === this.card.id) {
        this.flipped = false
        this.cleanCardNumbers()
      }
    })
    EventBus.on('card-3ds-shown', async (cardIdToHide) => { //
      if (cardIdToHide === this.card.id) {
        this.loading = true
        await this.setCardsInfos()
        this.loading = false
      }
    })
    EventBus.on('card-3ds-hide', (cardIdToHide) => { //
      if (cardIdToHide === this.card.id) {
        this.loading = true
        this.cleanCardNumbers()
        this.loading = false
      }
    })
    EventBus.on('code-pin-hide', (cardIdToHide) => { //
      if (cardIdToHide === this.card.id) {
        this.loading = true
        this.cleanCardNumbers()
        this.loading = false
      }
    })
    EventBus.on('code-pin-shown', async (cardIdToShown) => { //
      if (cardIdToShown === this.card.id) {
        this.loading = true
        await this.setCardsInfos()
        this.loading = false
      }
    })
    this.loading = false
  },
  emits: ['error'],
  methods: {
    async setCardsInfos() {
      this.keys = await this.generateKeys()

      const exportedPubKey = await this.exportPublicKey()

      const response = await getWallesterCardDatas(
        {id: this.card.id, publicKey: exportedPubKey},
        {}
      ).catch(error => {
        this.$emit("error")
        this.loading = false
      })

      console.log("Get wallester card data ", response.data)

      this.displayedCard = await this.decode(response.data.encrypted)
      this.displayedCard.expiration = getDateToExpirationFormat(
        response.data.expiry
      )
      this.displayedCard.secret = await this.decodeSecret(response.data.secret)
    },
    /**
     *  generate two keys (private / public)
     * @returns Promise
     */
    generateKeys() {
      return window.crypto.subtle.generateKey(
        {
          name: "RSA-OAEP",
          modulusLength: 4096,
          publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
          hash: {name: "SHA-256"}
        },
        true,
        ["decrypt"]
      )
    },
    /**
     * export the public key in good format RSA
     * @returns {string} base64 encoded key
     */
    async exportPublicKey() {
      const exported = await window.crypto.subtle.exportKey(
        "spki",
        this.keys.publicKey
      )

      const exportedAsString = this._ab2str(exported)
      const exportedAsBase64 = window.btoa(exportedAsString)
      const pemExported = `-----BEGIN PUBLIC KEY-----\n${exportedAsBase64}\n-----END PUBLIC KEY-----`
      // console.log("** export ** key pem ", pemExported);
      return pemExported
    },

    /**
     * decode string response to get card infos
     * @param {string} string to decode
     * @returns {object} cardToDisplay
     */
    async decode(encryptedString) {
      const enc = new TextEncoder()
      const dec = new TextDecoder()
      const encrypted = encryptedString.split("----------bloc--------")

      console.log("encrypted ", encrypted)

      const fromBase64 = base64String =>
        Uint8Array.from(window.atob(base64String), c => c.charCodeAt(0))

      // decode CVV
      const encryptedCardCVV = fromBase64(
        encrypted[1]
          .replace("\n-----BEGIN CVV2 MESSAGE-----\n", "")
          .replace("\n-----END CVV2 MESSAGE-----\n\n", "")
      )

      const cardCvv = await window.crypto.subtle.decrypt(
        {
          name: "RSA-OAEP",
          label: enc.encode("CVV2")
        },
        this.keys.privateKey,
        encryptedCardCVV
      )

      const encryptedCardNum = fromBase64(
        encrypted[2]
          .replace("\n-----BEGIN CardNumber MESSAGE-----\n", "")
          .replace("\n-----END CardNumber MESSAGE-----\n", "")
      )

      const cardNum = await window.crypto.subtle.decrypt(
        {
          name: "RSA-OAEP",
          label: enc.encode("CardNumber")
        },
        this.keys.privateKey,
        encryptedCardNum
      )

      // decode Pin
      let cardPin;
      if (this.card.plastic) {
        const encryptedCardPin = fromBase64(
          encrypted[3]
            .replace("\n-----BEGIN PIN MESSAGE-----\n", "")
            .replace("\n-----END PIN MESSAGE-----\n", "")
        )
        cardPin = await window.crypto.subtle.decrypt(
          {
            name: "RSA-OAEP",
            label: enc.encode("PIN")
          },
          this.keys.privateKey,
          encryptedCardPin
        )
      }
      return {
        number: dec.decode(new Uint8Array(cardNum)),
        cvv: dec.decode(new Uint8Array(cardCvv)),
        pin: (cardPin) ? dec.decode(new Uint8Array(cardPin)) : null
      }
    },

    async decodeSecret(encryptedString) {
      const enc = new TextEncoder()
      const dec = new TextDecoder()

      const fromBase64 = base64String =>
        Uint8Array.from(window.atob(base64String), c => c.charCodeAt(0))

      // decode CVV
      const secret = fromBase64(encryptedString)

      // console.log("ENCRYPTED CARD CVV", secret);

      const decryptedSecret = await window.crypto.subtle.decrypt(
        {
          name: "RSA-OAEP"
        },
        this.keys.privateKey,
        secret
      )

      // console.log("secret decrypted:", dec.decode(new Uint8Array(decryptedSecret)));

      return dec.decode(new Uint8Array(decryptedSecret))
    },

    copyCardNum() {
      navigator.clipboard.writeText(this.displayedCard.number).then(
        () => {
          console.log("card num copié ", this.displayedCard.number)
          this.showAlert(this.$t("card.copyAlert"))
        },
        () => {
          console.error("card num copy failed ", this.displayedCard.number)
          this.showAlert(false)
          /* clipboard write failed */
        }
      )
    },
    copyCardSecret() {
      navigator.clipboard.writeText(this.displayedCard.secret).then(
        () => {
          console.log("card secret copié ", this.displayedCard.secret)
          this.showAlert(this.$t("card.copyAlert"))
        },
        () => {
          console.error("card secret failed ")
          this.showAlert(false)
          /* clipboard write failed */
        }
      )
    },

    showAlert(text) {
      if (text) {
        this.copyAlert = text
        const to = setTimeout(() => {
          this.copyAlert = false
          clearTimeout(to)
        }, this.copyAlertTimeout)
      } else {
        this.copyAlert = false
      }
    },

    cleanCardNumbers() {
      this.displayedCard.number = ""
      this.displayedCard.cvv = ""
      this.displayedCard.expiration = ""
      this.displayedCard.secret = null
    },

    /** UTILITIES */

    /* array buffer to string */
    _ab2str(buf) {
      return String.fromCharCode.apply(null, new Uint8Array(buf))
    }
  }
}
