<template>
  <div class="help row align-center padding-1" :class="{'fl-type' : isFLType}">
    <Popup v-if="showPolitics" hasClose @close="togglePolitics()">
      <ViewBlocs :blocs="$tm('politics.blocs')" section="politics"/>
    </Popup>

    <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
      <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
      <p class="popup-valid-text text-center">{{ $t('help.moreHelp.submitOk') }}</p>
    </Popup>

    <ViewHeader :title="$t('help.title')" :url-back="'home'"/>
    <!-- HELP MORE -->
    <div class="help-more">
      <p v-if="showMoreHelp" v-html="$t('help.moreHelp.content')"></p>

      <!-- HELP FORM -->
      <FormWrapper
        v-if="showMoreHelp"
        :api="sendFormHelp.bind(this, this.form)"
        :btn-text="`${$t('help.moreHelp.submitTxt')}`"
        :validation="v$.form"
        :cancel-disabled="true"
        @submitted="onSubmitted"
        @error="onError"
      >
        <fieldset>
          <div class="row align-center">
            <InputWrapper :label="`${$t('form.subject')}`" class="columns small-12 medium-7"
                          :validation-path="'form.subject'">
              <select
                name="subject"
                :placeholder="$t('form.subject')"
                v-model.lazy="v$.form.subject.$model"
                required
              >
                <option v-for="(sub, index) in subjects" :key="index" :value="sub">{{ sub }}</option>
              </select>
            </InputWrapper>

            <InputWrapper :label="`${$t('form.message')}`" class="columns small-12 medium-7"
                          :validation-path="'form.message'">
                <textarea
                  name="message"
                  :placeholder="$t('form.message')"
                  v-model="v$.form.message.$model"
                  required
                />
            </InputWrapper>

            <label class="columns small-12 medium-7 text-justify legal-link legal-margin">
              {{ $t('help.moreHelp.formIndications') + ' ' + $t('form.politics.begin') }}
              <span
                @click="togglePolitics()"
              >{{ $t('form.politics.click') }}</span>
              {{ $t('form.politics.end') }}
            </label>
            <label
              class="columns small-12 medium-7 text-justify legal-link legal-margin"
              v-html="$t('help.moreHelp.formIndicationsMore')"
            ></label>
          </div>
        </fieldset>
      </FormWrapper>
    </div>
  </div>
</template>

<script src="./help.js"></script>
