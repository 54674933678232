<template>
<!-- Request cards data -->
<GetterWrapper id="xpay" :api-load="apiLoad" @data="onData">
    <div class="xpay row align-center">
        <ViewHeader :hasBack="true" urlBack="home" :title="OS === 'unknown' ? $t('card.actions.xpay') : (OS === 'android' ? $t('card.actions.xpayANDROID') :  $t('card.actions.xpayIOS'))" backgroundIcon="white" />
        <div class="columns small-12 medium-10 large-8">
            <ViewDetails :content="$t('card.xpay.intro')" />

            <div class="xpay-image"></div>

            <!-- Tabs -->
            <div class="row small-align-center xpay-tabs" :class="{invisible : (OS !== 'unknown' && OS !== item)}">
                <div v-for="(item, index) in items"
                     :id="item.replaceAll('.','-')"
                     :key="index" class="columns shop-tab xpay-tab"
                     :class="{ active: (activeItem === index)}"
                     @click.prevent="setActive(index)">
                    {{ $tm('card.xpay.devices.' + item)['title'] }}
                </div>
            </div>

            <!-- xpay items / tabs content -->
            <div v-for="(item, index) in items" :key="'xpay-item-'+index" :class="{invisible : OS !== 'unknown' && OS !== item}">
                <div v-if="activeItem === index" class="row small-12 xpay-items shop-items">
                    <div :class="'columns xpay-add text-center '+ item">
                        <div class="xpay-logo"></div>
                        <button v-if="OS !== 'unknown'" class="button" @click="openXpay">
                            {{ $tm('card.xpay.devices.' + item + '.button') }}
                        </button>
                    </div>

                    <div id="xpay-help" class="columns small-12  xpay-help">
                        <div class="xpay-help-bloc" v-for="(bloc, index) in $tm('card.xpay.devices.' + item + '.blocs')" :key="'help-bloc-' + index">
                            <ActionText class="help-subject" :icon="'sofont-arrow-down' + (currentHelp === index ? ' is-up' : '')" :isTitle="true" :text="bloc.title" v-on:action="openHelp(index)" />
                            <p v-if="currentHelp === index && bloc.text" v-html="bloc.text" class="column" ></p>
                            <div v-if="currentHelp === index && bloc.videoId" class="column video-wrapper" >
                              <iframe  :src="'https://www.youtube.com/embed/' + bloc.videoId"
                              title=""
                              frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen></iframe>
                            </div>
                             <a v-if="currentHelp === index && bloc.more" target="_blank" class="xpay-more" :href="bloc.more">{{$t('card.xpay.more')}}</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</GetterWrapper>
</template>

<script src="./xpay.js"></script>

<style lang="scss" src="./xpay.scss">
</style>
