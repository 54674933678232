import RoundIcon from "@/components/round-icon/round-icon.vue";
import router from "@/router";

export default {
  name: "BudgetItem",
  components: {RoundIcon},
  props: {
    budget: {type: Object, default: null},
  },
  data() {
    return {}
  },
  mounted: function () {
  },
  computed: {
    numberDaysToExpire() {
      if (!this.budget.expireAt) return 0;

      let dateExpire = new Date(this.budget.expireAt)

      let today = new Date();
      let differenceInTime = dateExpire.getTime() - today.getTime();
      return Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

    }
  },
  methods: {
    goToStore() {
      router.push({
        name: 'card-item-store',
        params: {id: this.budget.id}
      })
    },
    getTypeBudget(type) {
      if (!type) return "";
      type = type.replace(/\d+/g, '');
      return type.toLowerCase()
    }
  },
}
