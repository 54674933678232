<template>
  <div class="preferences row align-center">
    <ViewHeader :hasBack="true" urlBack="account" :title="$t('account.actions.preferences')"/>
    <div class="columns small-12 medium-10 large-8">
      <!--ViewDetails /-->

      <Popup v-if="showPopupOk" hasClose @close="closePopupOk()">
        <div class="popup-valid-icon"><i class="sofont sofont-check"></i></div>
        <p class="popup-valid-text text-center">{{ $t('forms.preferences.sendOk') }}</p>
      </Popup>

      <FormWrapper
        forceId="preferences"
        :api="saveUserPreferences.bind(this, this.form)"
        :btnDisabled="!isModified"
        :btnText="`${$t('form.save')}`"
        :validation="v$.form"
        @cancel="this.resetForm"
        @submitted="onSubmitted"
        @error="onError"
      >
        <fieldset>
          <legend>{{$t('form.newsletters')}}</legend>
          <div class="row align-center">
            <InputWrapper class="columns small-12 medium-7" :validation-path="'form.newsletter'" >
              <div class="toogle-input">
                <label class="toogle-title">{{ $t('form.autorizeNewsletters') }}</label>
                <div class="toogle-item">
                  <label>{{ $t('form.email') }}</label>
                  <Toggle name="newsletter"
                    css-colors
                    v-model="v$.form.newsletter.$model"
                    required
                    @input="update()" />

                </div>
              </div>
            </InputWrapper>
          </div>
        </fieldset>

        <fieldset>
          <legend>{{$t('form.zone')}}</legend>
          <div class="row align-center">
            <InputWrapper class="columns small-12 medium-7" :validation-path="'form.zoneId'" >
              <select name="zoneId" v-model.lazy="v$.form.zoneId.$model" @focus="update()" required>
                <option
                  v-for="zone in zonesId"
                  :key="zone.key"
                  :value="zone.id"
                >{{ zone.id + ' ' + zone.utc }}</option>
              </select>
            </InputWrapper>
          </div>
        </fieldset>

        <fieldset>
          <legend>{{$t('form.locale')}}</legend>
          <div class="row align-center">
            <InputWrapper class="columns small-12 medium-7" :validation-path="'form.locale'" >
              <LocalesInput :locale="v$.form.locale.$model" @update="updateLocale" />
            </InputWrapper>
          </div>
        </fieldset>
      </FormWrapper>
    </div>
  </div>
</template>


<script src="./preferences.js"></script>
