import moment from "moment-timezone"
import RoundIcon from "@/components/round-icon/round-icon.vue";

export default {
  name: 'ItemReward',
  components: {RoundIcon},
  props: {
    expiration: {
      type: Object,
      default: () => null,
      required: true
    },
    isOpenned: {
      type: Boolean,
      default: false
    },
    open: {
      type: Function,
      default: () => {
      }
    },
  },
  data() {
    return {}
  },
  computed: {
    label() {
      console.log('type ', this.expiration.metas.TYPE_M.toLowerCase())
      return this.$t('entries.' + (this.expiration.metas.TYPE_M.toLowerCase()), {value: '' + this.expiration.label + ''})

    }
  },
  methods: {
    amountConsumption() {
      if (this.expiration && this.expiration.metas.ENTRY_CONSUMPTION_REF) {
        let amount = 0
        this.expiration.metas.ENTRY_CONSUMPTION_REF.forEach(entry => {
          amount += entry.transactionCurrencyAmountConsumed
        })
        return this.expiration.amount - amount;
      }
      return 0;
    },
    isUrgent() {
      if (!this.expiration && !this.expiration.metas.DATE_EXPIR) return false
      const dateExpiration = new Date(this.expiration.metas.DATE_EXPIR);

      const today = new Date();

      const todayPlus30Days = new Date();
      todayPlus30Days.setDate(todayPlus30Days.getDate() + 30);

      return todayPlus30Days > dateExpiration && dateExpiration>today;
    },
    isExpired() {
      if (!this.expiration && !this.expiration.metas.DATE_EXPIR) {
        return false
      }

      let dateExpiration = new Date(this.expiration.metas.DATE_EXPIR);

      let today = new Date();
      return today > dateExpiration;
    },
    dateDistribution() {
      if (this.expiration && this.expiration.date) {
        return new Date(this.expiration.date)
      }
      // return new Date(this.expiration.date);
      return null;
    },
  }
}
