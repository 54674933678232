import ViewBlocs from "@/components/view-blocs/view-blocs.vue"
import ViewHeader from "@/components/view-header/view-header.vue"
import store from "@/store"
import i18n from '@/helpers/i18n'

export default {
  name: 'TCOS',
  components: {
    ViewBlocs,
    ViewHeader
  },
  props: {
  },
  computed: {
    tos() {
      return this.$tm("tos.wallester")
    }
  },
  data() {
    return {
    }
  },
}
