<template>
  <div class="how-to-use row align-center padding-1">
    <ViewHeader
      :hasBack="true"
      urlBack="home"
      :title="$t('card.howToUse.title')"
    />
    <div class="how-to-use row align-center">
      <div class="columns small-12 medium-10 large-8">

        <p v-html="$t('card.howToUse.text')"/>
      </div>
    </div>
    <!-- Actions texts -->
    <div
      class="row small-up-1 medium-up-2 large-up-2 align-center"
    >
      <button
        ref="goToFaq"
        id="go-to-faq"
        class="button mx-auto"
        v-html="$t('card.howToUse.faq')"
        @click="goToFaq"
      >
      </button>
    </div>
  </div>

</template>

<script src="./how-to-use.js">
</script>
<style lang="scss" src="./how-to-use.scss">
</style>
