import {createI18n} from 'vue-i18n'
import http from '@/http'
import axios from 'axios'
import store from '@/store'
import {isProduction} from '@/store/api'
import {changeGlobalDateLocale} from '@/helpers/date.js'

// change only this parameter if you want to get online languages in developpement mode
const TEST_ONLINE_LANG = false
// Create i18n instance
// --------------------


export const i18n = createI18n({
  locale: navigator.language, // set locale
  globalInjection: true,
  fallbackLocale: ['en-GB'],
  //allowComposition: true,
  //legacy: false,
  messages: {}, // set locale messages
  datetimeFormats: {
    'en-GB': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        separator: '-',
      },
      time: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }
    },
    'en': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        separator: '-',
      },
      time: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }
    },
    'fr': {
      short: {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        separator: '-',
      },
      time: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      }
    }
  }
})


// Live i18n loading
// --------------------

// Set the language for the session
function setI18nLanguage(lang) {
  console.log('set i18n language', lang)

  if (lang) {
    i18n.global.locale = lang
    changeGlobalDateLocale(lang)
    const langHTML = lang.split('-')[0]
    http.defaults.headers.common['Accept-Language'] = langHTML
    document.querySelector('html').setAttribute('lang', langHTML)
  }
  return lang
}

// Import the language datas (remote or local if development)
async function downloadLanguage(theme, lang) {
  // Debug
  //console.debug(`[DEBUG] Download translation ${lang}...`)

  // Load current language file
  let messages = ''
  if (isProduction() || TEST_ONLINE_LANG) {

    // fetch the file
    //console.debug("Start load remote:", `${theme.i18nBaseUrl}/${lang}.json`)
    messages = (await axios.get(`${theme.i18nBaseUrl}/${lang}.json`)).data
  } else {
    //console.debug("Start load local:", `@/locales/${lang}.json`)
    messages = (await import(`@/locales/${lang}.json`))

  }
  //console.debug("Messages local for lang :", lang, messages)

  // Returns message
  return messages

}

// Load and apply a language for that session
export async function loadLanguageAsync(lang) {
  //console.log('load language')
  // lang = 'en-US'

  // Get the platform
  const platform = store && store.state && store.state.platform ? store.state.platform : null
  const theme = platform ? platform.theme : null

  //console.debug('LOAD LANGUAGE ASYNCfor lang ', lang, theme, theme.i18n)
  // Reject if no theme
  if (!theme || !theme.i18n) {
    return Promise.reject(new Error(`The language asked '${lang}' is not supported.
    Theme is not available.`))
  }

  // Search for locale
  let locale = theme.i18n.find(l => (new RegExp(`^${lang}`).test(l)))
  //console.log('locale is ', locale)
  for (let i = 0; i < theme.i18n.length; i++) {
    const elt = theme.i18n[i]
    // console.log('in theme ', theme.i18n, elt, lang)
    if (lang && lang.startsWith(elt)) {
      locale = elt
    }
    const message = await downloadLanguage(theme, elt)
    // console.log('message ', elt, message)
    i18n.global.setLocaleMessage(elt, message)
    //console.debug("setLocaleMessage:", elt, message)
  }
  i18n.global.setDateTimeFormat(locale, i18n.global.getLocaleMessage(locale).dateTimeFormats)
  i18n.global.setNumberFormat(locale, i18n.global.getLocaleMessage(locale).numberFormats)
  //console.log('set numberFormats ', i18n, locale, i18n.global.getLocaleMessage(locale).numberFormats)
  // Set the language for the user
  return setI18nLanguage(locale)

}

export function changeNumberFormats(locale, numberFormats) {
  //console.log('i18n change number formats', i18n, locale, numberFormats)
  i18n.global.setNumberFormat(locale, numberFormats)
}

export function getCurrencySymbol() {
  const currency = new Intl.NumberFormat(i18n.global.locale, i18n.global.getNumberFormat(i18n.global.locale).currency).formatToParts(0).find(part => part.type === 'currency')
  if (currency) return currency.value
  return ''
}
